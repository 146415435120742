import { defineStore } from "pinia";
import { authStore } from "@/views/auth/stores/auth.store";
import appService from "@/views/app.service";
import { i18n } from "@/i18n";
import { showSuccessAlert } from "@/helpers/sweetAlert";

export const appStore = defineStore('appStore', {
    state: () => ({
        error: {
            message: '',
            type: '',
            popupTime: 5000,
            percentage: '0%'
        },
        form: {
            description: '',
            user_id: ''
        },
        navItemsLoaded: false,
        showBugReportModal: false,
        showUpdateModal: false,
        showUpgradeFormModal: false,
        isBugReportLoading: false,
        showSideNavigation: true,
        isMobile: false,
        showConsent: false,
        cookiesDenied: false
    }),
    actions: {
        async submitBugReport() {
            const useAuthStore = authStore();
            this.form.user_id = useAuthStore.user.uuid;

            try {
                await appService.postBug(this.form).then(() => {
                    this.form.description = '';
                    this.showBugReportModal = false;
                    showSuccessAlert('thank you!', 'bug report sent');
                });
            } catch (error) {
                this.setError(`failed to submit bug report`);
            }

            this.isBugReportLoading = false;
        },
        setError(message, type = 'error', totalTime = this.error.popupTime) {
            if (!this.error.message) {
                this.error.message = i18n.t(message).capitalize();
                this.error.type = type;
                const updateInterval = 25;
                let elapsed = 0;
                const interval = setInterval(() => {
                    elapsed += updateInterval;
                    this.error.percentage = `${elapsed / totalTime * 100}%`;
                    if (elapsed >= totalTime) {
                        clearInterval(interval);
                        this.error.message = '';
                        this.error.percentage = '0%';
                    }
                }, updateInterval);
            }
        },
        showConsentAgain() {
            this.showConsent = true;
            this.cookiesDenied = false;
        },
        eraseCookie(name) {
            document.cookie = name + '=; Max-Age=-99999999;';
        },
        acceptCookies() {
            console.log('cookies accepted');
            this.setCookie('cookies_accepted', 'true', 365);
            this.showConsent = false;
            this.cookiesDenied = false;
        },
        refuseCookies() {
            console.log('cookies denied');
            this.setCookie('cookies_accepted', 'false', 365);
            this.showConsent = false;
            this.cookiesDenied = false;
            this.eraseCookie('accessToken');
            this.eraseCookie('workspace');
        },
        giveEssentialCookies() {
            console.log('essential cookies given');
            this.setCookie('cookies_accepted', 'essential', 365);
            this.showConsent = false;
            this.cookiesDenied = false;
        },
        setCookie(name, value, days) {
            const d = new Date();
            d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
            const expires = "expires=" + d.toUTCString();
            document.cookie = name + "=" + value + ";" + expires + ";path=/";
        }
    }
});