<template>
  <div class="p-2 w-full overflow-x-auto md:h-[calc(100vh-140px)] relative">
    <div class="flex justify-between items-center mb-4">

      <div class="font-medium text-[#d43a65] mb-4">
        <h1 class="py-3 text-lg lg:text-3xl text-black font-bold">
          {{ $t('categories').capitalize() }}
        </h1>
        {{ $t('amount of main categories').capitalize() }}: {{ categories.length }}
      </div>
      <div>
        <button-component @click="openCreateModal()" v-if="useAuthStore.permissions.includes('users_create')"
                          buttonText="add category" :always-show-text="true" buttonColor="action"/>
      </div>
    </div>
    <div
        class="border-2 text-xs lg:text-lg rounded-xl lg:rounded-3xl border-[#ECEAE3] shadow-sm lg:p-12 p-6 flex-1 overflow-y-auto md:h-[calc(60vh-20px)] no-scrollbar">
      <dl>
        <category-list :categories="categories" :depth="0"/>
      </dl>
    </div>
    <loading-component v-if="useCategoryStore.categoriesListLoading"/>
  </div>
</template>

<script>
import {authStore} from "@/views/auth/stores/auth.store.js";
import {categoryStore} from "@/views/categories/stores/category.store";
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
import LoadingComponent from "@/components/LoadingComponent.vue";
import CategoryList from "@/views/categories/components/CategoryList.vue";

export default {
  name: 'OverviewUserComponent',
  components: {CategoryList, LoadingComponent, ButtonComponent},

  setup() {
    const useCategoryStore = categoryStore();
    const useAuthStore = authStore();

    return {
      useCategoryStore,
      useAuthStore
    }
  },

  props: {
    categories: {
      required: true,
      type: Array
    }
  },

  methods: {
    /**
     * Opens the create category modal.
     */
    openCreateModal() {
      this.useCategoryStore.showEditCategoryModal = true
      this.useCategoryStore.editMode = false
    },
  }
}
</script>