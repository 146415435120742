<template>
  <div class="min-h-screen flex bg-gradient-to-b from-gray-50 to-white z-20">
    <transition name="slide">
      <div
          v-if="useAppStore.showSideNavigation"
          :class="`flex flex-col items-center bg-[#ECEAE3] text-gray-700 shadow-lg ml-12 mr-12 mt-10 mb-10 rounded-2xl transition-all duration-500 h-[calc(100vh-80px)]`"
      >
        <span
            class="flex flex-col items-center py-4 bg-[#ECEAE3] relative w-full rounded-full"
        >
          <img
              :src="useAuthStore?.image ? useAuthStore?.image : `https://ui-avatars.com/api/?size=35&name=${useAuthStore.workspace?.name}&rounded=true&background=fff&color=51AFE3`"
              alt="User Avatar"
              class="rounded-full border-4 border-white shadow-lg mx-auto transition-full"
              :class="{ 'lg:h-28 lg:w-28 h-20 w-20': isExpanded, 'h-20 w-20': !isExpanded }"
          />
          <span
              class="absolute bottom-2 text-xs bg-[#EB59AB] text-white rounded-full px-4 py-1 mt-2"
          >
            {{
              useAuthStore.role &&
              (useAuthStore?.role?.name === 'demo_user' || useAuthStore?.role?.name === 'reseller_admin')
                  ? useAuthStore.translatedRole.capitalize()
                  : useAuthStore.workspace?.name
            }}
          </span>
        </span>

        <nav class="flex-1 flex flex-col mt-4 lg:mt-8 space-y-3 lg:space-y-4 w-full overflow-auto">
          <div
              v-for="navItem in navItems"
              :key="navItem.title"
              class="text-center w-full"
          >
            <RouterLink
                v-if="useAuthStore.permissions?.includes(navItem.permission) || !navItem.permission"
                :to="navItem.to"
                @click.prevent="useAppStore.isMobile ? useAppStore.showSideNavigation = !useAppStore.showSideNavigation : ''"
                class="flex items-center text-gray-700 transition-all text-sm lg-text-md"
                :class="{
                'text-selected': $route.path === navItem.to,
                'flex-row lg:pl-6 pl-4': isExpanded,
                'flex-col': !isExpanded,
                'bg-white rounded-full mx-4 lg:px-4': $route.path === navItem.to,
                'hover:bg-white hover:rounded-full hover:mx-4 hover:px-4': true
              }"
            >
              <div
                  class="relative flex items-center justify-center w-12 h-10 lg:h-14 rounded-full transition duration-300 hover:bg-white"
              >
                <i
                    :class="[navItem.icon, 'text-lg lg:text-2xl transition-colors duration-300 mt-1.5', { 'text-selected': $route.path === navItem.to }]"
                ></i>
              </div>
              <span
                  v-if="isExpanded"
                  :class="{ 'text-selected': $route.path === navItem.to }"
                  class="lg:ml-4 text-xs lg:text-lg"
              >
                {{ $t(navItem.title.lowercase()).capitalize() }}
              </span>
            </RouterLink>
          </div>
        </nav>
        <div class="mt-4 mb-4 w-full space-y-3 lg:space-y-4">
          <RouterLink
              v-if="useAuthStore.permissions?.includes('workspaces_update_own')"
              to="/settings"
              class="flex items-center text-gray-700 transition-all h-10 lg:h-14 text-sm lg:text-md hover:bg-white hover:rounded-full hover:mx-4 hover:px-4"
              :class="{
              'text-selected': $route.path === '/settings',
              'flex-row lg:pl-6 pl-4': isExpanded,
              'flex-col': !isExpanded,
              'bg-white rounded-full mx-4 px-4': $route.path === '/settings',
            }"
          >
            <div
                class="relative flex items-center justify-center w-12 h-10 rounded-full transition duration-300"
            >
              <icon-component
                  icon="fi fi-rr-settings"
                  class="text-lg lg:text-2xl mt-1.5"
              ></icon-component>
            </div>
            <span v-if="isExpanded" class="lg:ml-4 text-xs lg:text-lg">
              {{ $t('settings').capitalize() }}
            </span>
          </RouterLink>

          <RouterLink
              to=""
              @click.prevent="handleLogout"
              class="flex items-center text-gray-700 transition-all h-10 lg:h-14 text-sm lg-text-md hover:bg-white hover:rounded-full hover:mx-4 hover:px-4"
              :class="{
              'text-selected': $route.path === '/logout',
              'flex-row lg:pl-6 pl-4': isExpanded,
              'flex-col': !isExpanded,
              'bg-white rounded-full mx-4 px-4': $route.path === '/logout',
            }"
          >
            <div
                class="relative flex items-center justify-center w-12 h-10 rounded-full transition duration-300"
            >
              <i
                  :class="['fi fi-rs-sign-out-alt', 'text-lg lg:text-2xl transition-colors duration-300 mt-1.5', { 'text-selected': $route.path === '/logout' }]"
              ></i>
            </div>
            <span v-if="isExpanded" class="lg:ml-4 text-xs lg:text-lg">
              {{ $t('logout').capitalize() }}
            </span>
          </RouterLink>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {authStore} from "@/views/auth/stores/auth.store";
import {appStore} from "@/views/app.store";
import {useRouter} from "vue-router";
import IconComponent from "@/components/forms/IconComponent.vue";

export default {
  components: {
    IconComponent,
  },
  setup() {
    const useAuthStore = authStore();
    const useAppStore = appStore();
    const router = useRouter();
    return {
      useAuthStore,
      router,
      useAppStore,
    };
  },
  data() {
    return {
      isExpanded: true,
      navItems: [
        {name: "Home", to: "/", title: "Dashboard", icon: "fi fi-rr-home"},
        {
          name: "Lessons",
          to: "/library",
          title: "Library",
          icon: "fi fi-rr-book-alt",
          permission: "books_view",
        },
        {
          name: "Class Weeks",
          to: "/agenda",
          title: "Class Programs",
          icon: "fi fi-rr-calendar-day",
          permission: "class_weeks_view",
        },
        {
          name: "Users",
          to: "/users",
          title: "Users",
          icon: "fi fi-rs-users",
          permission: "users_view",
        },
        {
          name: "Categories",
          to: "/categories",
          title: "Categories",
          icon: "fi fi-rr-layers",
          permission: "categories_view",
        },
        {
          name: "Analytics",
          to: "/analytics",
          title: "Analytics",
          icon: "fi fi-rr-chart-histogram",
          permission: "analytics_view",
        },
      ],
    };
  },
  methods: {
    /**
     * Handles the logout process.
     * Attempts to log out the user and logs an error if the process fails.
     */
    async handleLogout() {
      try {
        await this.useAuthStore.logout();
      } catch (error) {
        console.error("Logout failed:", error);
      }
    },
  },
};
</script>