<template>
  <div class="w-full flex flex-col items-center p-4 sm:p-8">
    <img alt="Fluitend Leren Logo" class="mb-4 sm:mb-8 ml-4 self-center" src="../../assets/logo_transparant.png">

    <div class="w-full max-w-4xl">
      <h1 class="text-xl mb-4 text-center">
        {{ $t('welcome to Fluitend Leren').capitalize() }}</h1>

      <h2 class="text-2xl mb-2 text-center">
        {{ $t('create demo account').capitalize() }}</h2>

      <p class="text-center mb-20">
        <button class="underline text-black" @click="goToLogin">
          {{ $t('back to login').capitalize() }}
        </button>
      </p>

      <div class="w-full justify-center mb-8">
        <div class="text-center mb-4">
          {{ $t('step').capitalize() + ' ' + currentStep + ' ' + $t('of') + ' ' + totalSteps }}
        </div>
        <div class="w-full bg-gray-200 rounded-full h-1.5 relative">
          <div ref="progressBar" class="bg-cta h-1.5 rounded-full absolute"></div>
        </div>
      </div>

      <swiper ref="mySwiper" :auto-height="true" :observeParents="true" :observer="true" :slides-per-view="1"
              :space-between="30" @slideChange="onSlideChange" @swiper="onSwiper">
        <swiper-slide>
          <input-component v-model="useAuthStore.demoForm.firstname" label="firstname" class="w-full mb-4"
                           input-color="primary" input-type="text" placeholder="John"
                           @change="validateInput"/>
          <input-component v-model="useAuthStore.demoForm.lastname" label="lastname" class="w-full mb-4"
                           input-color="secondary" input-type="text" placeholder="Doe"
                           @change="validateInput"/>
        </swiper-slide>

        <swiper-slide v-if="useAuthStore.demoForm.lastname">
          <input-component v-model="useAuthStore.demoForm.email" class="w-full" input-color="primary"
                           input-type="email" label="Email" placeholder="john.doe@example.com"
                           @change="validateInput"/>
        </swiper-slide>

        <swiper-slide v-if="useAuthStore.demoForm.email">
          <select-component v-model="useAuthStore.demoForm.selectedDescribe" :label="$t('select function').capitalize()"
                            :options="optionsDescribe" class="w-full mb-4" input-color="primary"
                            @change="onDescribeChange"/>
          <input-component v-if="showElseInput" 
                           v-model="useAuthStore.demoForm.elseDescribe"
                           class="w-full"
                           label="Please specify" 
                           placeholder="Specify 'else'" 
                           @change="validateInput"/>
        </swiper-slide>

        <swiper-slide
            v-if="useAuthStore.demoForm.selectedDescribe">
          <input-component v-model="useAuthStore.demoForm.schoolName" :label="$t('name of school').capitalize()" class="w-full mb-2"
                           input-color="primary" input-type="text" placeholder="Name of school"
                           @change="validateInput"/>
          <label class="flex space-x-2">
            <input v-model="useAuthStore.demoForm.acceptedPolicies" type="checkbox"/>
            <span class="text-sm" v-html="$t('acceptance text', {
              privacyPolicy: `<button @click='showPrivacyPolicy = true' class='underline text-black'>${$t('privacy policy').capitalize()}</button>`,
              termsAndConditions: `<button @click='showTermsOfService = true' class='underline text-black'>${$t('terms and conditions').capitalize()}</button>`
              }).capitalize()">
            </span>
          </label>
        </swiper-slide>
      </swiper>

      <div class="mt-4">

      </div>

      <privacy-policy v-if="showPrivacyPolicy" @close="showPrivacyPolicy = false"/>
      <terms-of-service v-if="showTermsOfService" @close="showTermsOfService = false"/>

      <div class="flex justify-center items-center mt-4 space-x-4">
        <button
            v-if="!isLeftmostPage"
            class="text-lg font-bold p-2" @click="goPrev">
          <icon-component icon="fi fi-br-arrow-left"></icon-component>
        </button>
        
        <button-component
            v-if="!isRightmostPage && !isFormComplete"
            :always-show-text="true"
            buttonText="Next"
            buttonColor="action"
            class="shadow-lg px-10 py-2 w-full"
            @click="goNext"
        />
        <button-component
            v-if="isRightmostPage || isFormComplete"
            :always-show-text="true"
            buttonText="Try Demo"
            :disabled="!isFormComplete"
            :is-loading="useAuthStore.isLoading"
            buttonColor="action"
            class="shadow-lg px-10 py-2 w-full"
            type="submit"
            @click="submitDemoForm"
        />

        <button
            v-if="!isRightmostPage"
            class="text-lg font-bold p-2" @click="goNext">
          <icon-component icon="fi fi-br-arrow-right"></icon-component>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, ref} from 'vue';
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/swiper-bundle.css';
import SwiperCore from 'swiper';
import SelectComponent from "@/components/forms/SelectComponent.vue";
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
import InputComponent from "@/components/forms/InputComponent.vue";
import IconComponent from "@/components/forms/IconComponent.vue";
import PrivacyPolicy from "@/views/auth/PrivacyPolicy.vue";
import TermsOfService from "@/views/auth/TermsOfService.vue";
import {authStore} from "@/views/auth/stores/auth.store";
import gsap from 'gsap';

SwiperCore.use([]);

export default {
  name: 'RegisterComponent',
  components: {
    IconComponent,
    InputComponent,
    ButtonComponent,
    SelectComponent,
    Swiper,
    SwiperSlide,
    PrivacyPolicy,
    TermsOfService
  },
  setup() {
    const useAuthStore = authStore();
    const mySwiper = ref(null);
    const progressBar = ref(null);

    const totalSteps = computed(() => 4);
    const currentStep = ref(1);

    return {useAuthStore, mySwiper, progressBar, totalSteps, currentStep};
  },
  data() {
    return {
      optionsDescribe: [
        {value: 'school leader', text: 'Schoolleider'},
        {value: 'adjunct director', text: 'Adjunct Directeur'},
        {value: 'intern supervisor', text: 'Intern Begeleider'},
        {value: 'teacher', text: 'Leerkracht'},
        {value: 'upper school board', text: 'Bovenschools Bestuur'},
        {value: 'working group with decision-making authority', text: 'Werkgroep met beslissingsbevoegdheid'},
        {value: 'else', text: 'Anders'}
      ],
      showPrivacyPolicy: false,
      showTermsOfService: false,
      elseDescribe: ''
    };
  },
  computed: {
    showInputDescribe() {
      return this.useAuthStore.demoForm.selectedDescribe === 'else';
    },
    isFormComplete() {
      const basicFieldsComplete = this.useAuthStore.demoForm.firstname &&
          this.useAuthStore.demoForm.lastname &&
          this.useAuthStore.demoForm.email &&
          (this.useAuthStore.demoForm.selectedDescribe || this.useAuthStore.demoForm.elseDescribe) &&
          this.useAuthStore.demoForm.schoolName;

      const elseFieldsComplete =
          (!this.showInputDescribe || this.useAuthStore.demoForm.elseDescribe.trim() !== '');

      return basicFieldsComplete && elseFieldsComplete && this.useAuthStore.demoForm.acceptedPolicies;
    },
    isLeftmostPage() {
      return this.currentStep === 1;
    },
    isRightmostPage() {
      return this.currentStep === this.totalSteps;
    },
    buttonText() {
      if (this.isRightmostPage == true) {
        return this.isRightmostPage ? 'Try Demo' : 'Next';
      } else {
        return !this.isFormComplete ? 'next' : 'Try Demo';
      }
    },
    showElseInput() {
      return this.useAuthStore.demoForm.selectedDescribe === 'else';
    },
  },
  methods: {
    /**
     * Navigates to the previous slide.
     */
    goPrev() {
      if (this.mySwiper.value) {
        this.mySwiper.value.slidePrev();
        this.updateProgressBar();
      }
    },
    /**
     * Navigates to the next slide.
     */
    goNext() {
      if (this.mySwiper.value) {
        this.mySwiper.value.slideNext();
        this.updateProgressBar();
      }
    },
    /**
     * Validates the input and navigates to the next slide if valid.
     */
    validateInput() {
      if (!this.showInputDescribe) {
        this.goNext();
      }
    },
    /**
     * Validates the "else" input and navigates to the next slide if valid.
     */
    validateElseInput() {
      if (this.useAuthStore.demoForm.elseDescribe.trim()) {
        this.goNext();
      }
    },
    /**
     * Submits the demo form if it is complete.
     */
    submitDemoForm() {
      if (this.isFormComplete) {
        this.useAuthStore.isLoading = true;
        this.useAuthStore.createDemoForm()
            .catch(errorData => {
                if (errorData?.errors) {

                    const mappedErrors = {
                        first_name: errorData.errors.first_name,
                        last_name: errorData.errors.last_name,
                        email: errorData.errors.email
                    };

                    this.goToErrorSlide(mappedErrors);

                }
            });
      }
    },
    /**
     * Navigates to the slide with errors.
     * @param {Object} errors - The errors object.
     */
    goToErrorSlide(errors) {
      if (errors.first_name || errors.last_name) {
        this.mySwiper.value.slideTo(0);
      } else if (errors.email) {
        this.mySwiper.value.slideTo(1);
      }
      this.updateProgressBar();
    },
    /**
     * Handles the button click event.
     */
    handleButtonClick() {
      if (this.isRightmostPage) {
        this.submitDemoForm();
      } else {
        this.goNext();
      }
    },
    /**
     * Updates the progress bar based on the current slide.
     */
    updateProgressBar() {
      const step = this.mySwiper.value ? this.mySwiper.value.activeIndex + 1 : 1;
      const newWidth = (step / this.totalSteps) * 100;
      gsap.to(this.$refs.progressBar, {width: `${newWidth}%`, duration: 0.5});
      this.currentStep = step;
    },
    /**
     * Handles the swiper initialization event.
     * @param {Object} swiper - The swiper instance.
     */
    onSwiper(swiper) {
      this.mySwiper.value = swiper;
      this.updateProgressBar();
    },
    /**
     * Handles the slide change event.
     */
    onSlideChange() {
      this.updateProgressBar();
    },
    /**
     * Navigates to the login page.
     */
    goToLogin() {
      this.$router.push('/login');
    }
  }
};
</script>

<style scoped>
.transition-width {
  transition: width 0.5s ease-in-out;
}
</style>