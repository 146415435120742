<template>
  <div class="p-2 w-full overflow-x-auto md:h-[calc(100vh-140px)] relative">
    <div class="flex justify-between items-center mb-4">
      <div class="font-medium text-[#d43a65] mb-4">
        <div class="py-3 text-3xl text-black font-bold">
          {{ $t('bug reports').capitalize() }}
        </div>
        {{ $t('amount of bugs').capitalize() }}: <span>{{ useAnalyticsStore.bugs.length }}</span>
      </div>
    </div>
    <div
        class="border-2 rounded-3xl border-[#ECEAE3] shadow-sm p-12 flex-1 overflow-y-auto md:h-[calc(60vh-20px)] no-scrollbar">
      <table class="w-full border-collapse">
        <thead>
        <tr class="border-b-2 text-left">
          <th class="py-2 px-4 text-sm font-medium text-gray-600 border-b">Name</th>
          <th class="py-2 px-4 text-sm font-medium text-gray-600 border-b">Email</th>
          <th class="py-2 px-4 text-sm font-medium text-gray-600 border-b">Description</th>
          <th class="py-2 px-4 text-sm font-medium text-gray-600 border-b">Date</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="bug in useAnalyticsStore.bugs" :key="bug.id" class="hover:bg-gray-50">
          <td class="py-2 px-4 text-sm text-gray-800 border-b">
            {{ bug.user.first_name }} {{ bug.user.last_name }}
          </td>
          <td class="py-2 px-4 text-sm text-gray-800 border-b">
            {{ bug.user.email }}
          </td>
          <td class="py-2 px-4 text-sm text-gray-600 border-b">
            {{ bug.description }}
          </td>
          <td class="py-2 px-4 text-sm text-gray-500 border-b">
            {{ bug.created_at.humanize() }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {analyticsStore} from "@/views/analytics/stores/analytics.store.js";

export default {
  name: 'BugListComponent',

  setup() {
    const useAnalyticsStore = analyticsStore();
    return {
      useAnalyticsStore
    }
  },

  mounted() {
    this.getBugReports();
  },

  methods: {
    /**
     * Fetches all bug reports and updates the store.
     * @returns {Promise<void>}
     */
    async getBugReports() {
      await this.useAnalyticsStore.getAllBugReports();
    }
  }
}
</script>