<template>
  <div class="p-8">
    <div class="flex flex-wrap justify-center md:justify-between items-center mb-4">
      <h1 class="text-sm md:text-2xl font-bold">{{ $t('class program').capitalize() }} {{ program.name }}</h1>
      <div class="flex mt-3 space-x-3 md:mt-0 md:space-x-0">
        <button-component
            class="mx-1"
            v-if="useAuthStore.permissions?.includes('class_weeks_update')"
            @edit="createProgram(program.books)"
            emit-name="edit"
            button-color="action"
            button-text="Edit"
            prefix-icon="fas fa-edit"
        ></button-component>
        <button-component
            class="mx-1"
            v-if="useAuthStore.permissions?.includes('class_weeks_delete')"
            @delete="confirmDeleteProgram"
            emit-name="delete"
            button-color="action"
            button-text="Delete"
            prefix-icon="fi fi-rr-trash"
        ></button-component>
      </div>
    </div>
    <div class="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-1 gap-4">
      <card
          v-for="library in program.books"
          :key="library.title"
          :class="['text-black rounded transform transition duration-200 hover:scale-105 hover:text-black cursor-pointer']"
          @show="openBook($event, library)"
          :is-demo="!!library.is_demo"
          :is-locked="isLocked(library.is_demo)"
          :hide-dropdown="true"
          :allow-edits="false"
          :delete-detail-button="false"
          :edit-detail-button="false"
          show-permission="class_weeks_view"
          :title="library.name"
          :categories="library.categories"
          :image="library.image"
          :views="library.views"
          :show-image="true"
          title-size="md"
      >
      </card>
    </div>
    <edit-agenda-view-component :program="program"/>
    <div v-if="!useAgendaStore.showEditProgramModal" class="md:hidden mb-20 fixed bottom-4 left-4 right-4 z-40">
      <button-component
          :disabled="useAgendaStore.books.length <= 0 || useAgendaStore.isLoading"
          :buttonText="$t('add program').capitalize()"
          buttonColor="action"
          :always-show-text="true"
          :ghost="true"
          class="mb-2 py-1 rounded-full w-full max-w-xs mx-auto text-md shadow-lg flex items-center justify-center"
          v-if="useAuthStore.permissions?.includes('class_weeks_create')"
          @click="createProgram()"
      />
    </div>
  </div>
</template>

<script>
import Card from "@/components/LessonCardComponent.vue";
import {libraryStore} from "@/views/library/stores/library.store.js";
import {agendaStore} from "@/views/agenda/stores/agenda.store.js";
import {authStore} from "@/views/auth/stores/auth.store.js";
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
import EditAgendaViewComponent from "@/views/agenda/EditAgendaView.vue";
import {showDeleteConfirmation} from "@/helpers/sweetAlert";

export default {
  name: "OverviewAgendaComponent",
  components: {EditAgendaViewComponent, ButtonComponent, Card},

  setup() {
    const useLibraryStore = libraryStore();
    const useAuthStore = authStore();
    const useAgendaStore = agendaStore();

    return {
      useAuthStore,
      useLibraryStore,
      useAgendaStore,
    };
  },

  props: {
    program: {
      required: true,
      type: Object,
    },
  },

  methods: {
    /**
     * Checks if the library is locked for the current user.
     * @param {Boolean} isDemo - Indicates if the library is a demo.
     * @returns {Boolean} - Returns true if the library is locked, otherwise false.
     */
    isLocked(isDemo) {
      return this.useAuthStore?.user?.roles[0]?.name === 'demo_user' && !isDemo;
    },

    /**
     * Creates a new program.
     * Sets the showEditProgramModal to true and resets the form.
     */
    createProgram() {
      this.useAgendaStore.setProgramBooks();
      this.useAgendaStore.showEditProgramModal = true;
      this.useAgendaStore.editMode = true;
    },

    /**
     * Confirms the deletion of the program.
     * Shows a delete confirmation dialog.
     */
    async confirmDeleteProgram() {
      showDeleteConfirmation(() => this.deleteProgram());
    },

    /**
     * Deletes the selected program.
     * @returns {Promise<void>}
     */
    async deleteProgram() {
      try {
        await this.useAgendaStore.deleteProgram();
      } catch (e) {
        console.error("Error deleting workspace:", e);
      }
    },

    /**
     * Opens the selected book.
     * @param {Event} $e - The event object.
     * @param {Object} book - The book to open.
     * @returns {Promise<void>}
     */
    async openBook($e, book) {
      try {
        await this.useLibraryStore.incrementViews(book.uuid);
        this.useLibraryStore.selectedBook = book;
        this.navigateTo($e, "/library/book/" + book.uuid);
      } catch (error) {
        console.error('Error opening book:', error);
      }
    },
  },
};
</script>