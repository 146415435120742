<template>
  <div v-if="modalIsOpen" class="fixed inset-0 flex justify-end items-start z-50 bg-black bg-opacity-50" @click="closeModal">
    <div
        class="bg-white w-full max-w-[850px] h-[85vh] mt-24 mr-8 rounded-[30px] shadow-lg p-8 relative z-70 overflow-y-auto transform -translate-x-10"
        @click.stop
    >
      <h1 class="text-2xl font-bold text-[#48B0ED] mb-6 flex items-center">
        <i class="fi fi-rr-bug mt-2 mr-3"></i>{{ $t('report bug').capitalize() }}
      </h1>

      <form @submit.prevent="submitBugReport">
        <div class="mb-40">
          <label for="topic" class="block text-lg font-semibold text-[#48B0ED] mb-2">
            {{ $t('i have a comment about').capitalize() }}:
          </label>
          <select
              id="topic"
              class="block w-[250px] p-3 border border-gray-300 rounded-[30px]  shadow-sm focus:ring-[#48B0ED] focus:border-[#48B0ED]"
          >
            <option value="">{{ $t('make a choice...').capitalize() }}</option>
            <option value="online">{{ $t('online environment').capitalize() }}</option>
            <option value="lesson">{{ $t('movement Learning lesson').capitalize() }}</option>
          </select>
        </div>

        <div class="mb-10">
          <label for="description" class="block text-lg font-semibold text-[#48B0ED] mb-2">
            {{ $t('comment').capitalize() }}
          </label>
          <textarea-component
              id="description"
              field-id="description"
              v-model="store.form.description"
              :placeholder="$t('describe the issue as accurately as possible')"
              rows="10"
              class="block w-full border-[#ECEAE3] rounded-[30px] shadow-sm focus:ring-[#48B0ED] focus:border-[#48B0ED] resize-none h-[calc(100% - 140px)]"
              :required="true"
          />
        </div>

        <div class="flex justify-start mt-[20px]">
          <button-component
              type="submit"
              button-color="action"
              class="text-sm px-16 py-1 rounded shadow-sm"
              button-text="submit"
              :is-loading="store.isBugReportLoading"
              prefix-icon="fi fi-br-paper-plane-top"
          />
        </div>
      </form>
    </div>
  </div>
</template>


<script>
import { appStore } from "@/views/app.store";
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
import TextareaComponent from "@/components/forms/TextareaComponent.vue";

export default {
  components: { TextareaComponent, ButtonComponent },
  props: {
    modalIsOpen: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const store = appStore();
    const submitBugReport = () => {
      store.isBugReportLoading = true;
      store.submitBugReport();
    };
    return {
      store,
      submitBugReport,
    };
  },
  methods: {
    closeModal() {
      this.store.showBugReportModal = false;
    },
  },
};
</script>
