<template>
  <div class="page-container pt-12 bg-transparent">
    <div
        class="top-navigation hidden md:flex fixed top-4 right-14 justify-end items-center p-4 rounded-lg max-w-2xl z-20">
      <div class="action-icons flex items-center space-x-4">
        <button
            @click="setBugReportModal"
            class="flex justify-center items-center lg:w-10 lg:h-10 w-8 h-8 border-2 border-gray-300 rounded-full hover:border-blue-500"
        >
          <i class="fi fi-sr-bug text-blue-500 lg:text-xl leading-none mt-1"></i>
        </button>
      </div>

      <bug-report-component
          :modalIsOpen="useAppStore.showBugReportModal"
          @close="setBugReportModal"
      />

      <update-component
          :modalIsOpen="useAppStore.showUpdateModal"
          @close="setUpdateModal"
      />
    </div>

    <div
        class="top-navigation md:hidden fixed top-4 right-4 flex justify-between items-center p-4 rounded-lg max-w-full z-50">
      <div class="search-bar flex items-center border border-gray-300 rounded-full px-4 py-2 w-80">
        <input
            type="text"
            v-model="searchQuery"
            :placeholder="$t('Look for something...').capitalize()"
            class="bg-transparent focus:outline-none w-full placeholder-gray-500 text-base"
        />
        <button>
          <i class="fi fi-rr-search text-blue-500 text-xl leading-none mt-0.5"></i>
        </button>
      </div>

      <button @click="navigateToSettings" class="ml-4 mt-0.5">
        <i class="fi fi-rr-settings text-black text-2xl"></i>
      </button>
    </div>
  </div>
</template>

<script>
import {appStore} from "@/views/app.store";
import BugReportComponent from "@/components/forms/BugReportComponent.vue";
import UpdateComponent from "@/components/forms/UpdateComponent.vue";
import {useRouter} from "vue-router";

export default {
  name: "TopNavigation",
  components: {BugReportComponent, UpdateComponent},
  setup() {
    const useAppStore = appStore();
    const router = useRouter();

    const navigateToSettings = () => {
      router.push("/settings");
    };

    return {
      useAppStore,
      navigateToSettings,
    };
  },
  data() {
    return {
      searchQuery: "",
    };
  },
  methods: {
    /**
     * Toggles the visibility of the Bug Report modal.
     */
    setBugReportModal() {
      this.useAppStore.showBugReportModal = !this.useAppStore.showBugReportModal;
    },
    /**
     * Toggles the visibility of the Update modal.
     */
    setUpdateModal() {
      this.useAppStore.showUpdateModal = !this.useAppStore.showUpdateModal;
    },
  },
};
</script>