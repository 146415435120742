<template>
  <div id="app">
    <popup-message v-if="useAppStore.error.message" :message="useAppStore.error.message" :type="useAppStore.error.type"
                   :percentage="useAppStore?.error?.percentage"/>
    <div v-if="useAppStore.navItemsLoaded">
      <div v-if="userLoggedIn && userHasWorkspace && useAuthStore.userIsEmailVerified">
        <UpgradeFormModal v-if="useAppStore.showUpgradeFormModal"/>
        <div class="flex">
          <side-navigation @toggle-expand="sidebarExpanded = $event"
                           :class="[sidebarExpanded ? 'w-[500px]' : 'w-[250px]']"
                           class="md:relative md:block hidden md:h-screen z-20 transition-all duration-500"
                           v-if="useAppStore.showSideNavigation"/>
          <div :class="['h-screen overflow-y-scroll transition-all duration-500']"
               class="w-full bg-gradient-to-b from-gray-50 relative">
            <top-navigation class="hidden md:block h-[75px]"/>
            <router-view class="h-[calc(100vh-75px)] p-8"/>
          </div>
          <mobile-navigation class="md:hidden absolute bottom-0 w-full"/>
        </div>
      </div>
      <div v-else>
        <router-view class="h-screen"/>
      </div>
    </div>
    <div v-else>
      <loading-component fill="black"/>
    </div>
    <cookie-consent/>
  </div>
</template>

<script>
import SideNavigation from './components/SideNavigation.vue';
import {getLogin, getUserProfile, getWorkspace} from './auth';
import {appStore} from '@/views/app.store';
import {authStore} from "@/views/auth/stores/auth.store";
import TopNavigation from "@/components/TopNavigation.vue";
import PopupMessage from "@/components/PopupMessage.vue";
import LoadingComponent from "@/components/LoadingComponent.vue";
import CookieConsent from "@/components/CookieConsent.vue";
import UpgradeFormModal from "@/components/forms/UpgradeFormModal.vue";
import MobileNavigation from "@/components/MobileNavigation.vue";

export default {
  name: 'App',
  components: {
    MobileNavigation,
    UpgradeFormModal,
    CookieConsent,
    LoadingComponent,
    PopupMessage,
    TopNavigation,
    SideNavigation
  },
  setup() {
    const useAppStore = appStore();
    const useAuthStore = authStore();

    return {
      useAppStore,
      useAuthStore
    };
  },
  data() {
    return {
      userLoggedIn: getLogin(),
      userHasWorkspace: getWorkspace(),
      sidebarExpanded: true
    };
  },
  mounted() {
    this.useAppStore.navItemsLoaded = true;
    this.isMobile();
    this.isEmailVerified();
    this.updateFavicon();
  },
  computed: {
    meta() {
      return this.$route.meta;
    },
  },
  methods: {
    /**
     * Checks if the user's email is verified and updates the store accordingly.
     */
    isEmailVerified() {
      getUserProfile();
      this.useAuthStore.user.email_verified_at ? this.useAuthStore.userIsEmailVerified = true : this.useAuthStore.userIsEmailVerified = false;
    },
    /**
     * Checks if the device is mobile and updates the store accordingly.
     */
    isMobile() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 768) {
        this.useAppStore.isMobile = true;
        this.useAppStore.showSideNavigation = false;
      } else {
        this.useAppStore.isMobile = false;
        this.useAppStore.showSideNavigation = true;
      }
    },
    /**
     * Updates the favicon based on the user's workspace image.
     */
    updateFavicon() {
      const workspaceImageUrl = this.useAuthStore?.image
          ? this.useAuthStore.image
          : `https://ui-avatars.com/api/?size=35&name=${this.useAuthStore.workspace?.name}&rounded=true&background=fff&color=51AFE3`;
      window.workspaceImageUrl = workspaceImageUrl;

      const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = workspaceImageUrl;
      document.getElementsByTagName('head')[0].appendChild(link);
    },
  },
  watch: {
    '$route': function () {
      this.userLoggedIn = getLogin();
      this.userHasWorkspace = getWorkspace();
      this.updateFavicon();
    },
    'useAuthStore.workspace': {
      handler() {
        this.updateFavicon();
      },
      deep: true
    }
  },
  created() {
    this.userLoggedIn = getLogin();
    this.userHasWorkspace = getWorkspace();
    this.updateFavicon();
  }
}
</script>