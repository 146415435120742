<template>
  <div class="loading-overlay">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
         style="margin: auto; background: none; display: block; shape-rendering: auto;" :width="size" :height="size"
         viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <g>
        <circle cx="79.354" cy="72.524" :fill="fill" r="4">
          <animateTransform attributeName="transform" type="rotate" calcMode="spline" values="0 50 50;360 50 50"
                            times="0;1" keySplines="0.5 0 0.5 1" repeatCount="indefinite" dur="1.8518518518518516s"
                            begin="0s"></animateTransform>
        </circle>
        <circle cx="72.524" cy="79.354" :fill="fill" r="4">
          <animateTransform attributeName="transform" type="rotate" calcMode="spline" values="0 50 50;360 50 50"
                            times="0;1" keySplines="0.5 0 0.5 1" repeatCount="indefinite" dur="1.8518518518518516s"
                            begin="-0.062s"></animateTransform>
        </circle>
        <circle cx="64.159" cy="84.184" :fill="fill" r="4">
          <animateTransform attributeName="transform" type="rotate" calcMode="spline" values="0 50 50;360 50 50"
                            times="0;1" keySplines="0.5 0 0.5 1" repeatCount="indefinite" dur="1.8518518518518516s"
                            begin="-0.125s"></animateTransform>
        </circle>
        <circle cx="54.829" cy="86.683" :fill="fill" r="4">
          <animateTransform attributeName="transform" type="rotate" calcMode="spline" values="0 50 50;360 50 50"
                            times="0;1" keySplines="0.5 0 0.5 1" repeatCount="indefinite" dur="1.8518518518518516s"
                            begin="-0.187s"></animateTransform>
        </circle>
        <circle cx="45.171" cy="86.683" :fill="fill" r="4">
          <animateTransform attributeName="transform" type="rotate" calcMode="spline" values="0 50 50;360 50 50"
                            times="0;1" keySplines="0.5 0 0.5 1" repeatCount="indefinite" dur="1.8518518518518516s"
                            begin="-0.25s"></animateTransform>
        </circle>
        <circle cx="35.841" cy="84.184" :fill="fill" r="4">
          <animateTransform attributeName="transform" type="rotate" calcMode="spline" values="0 50 50;360 50 50"
                            times="0;1" keySplines="0.5 0 0.5 1" repeatCount="indefinite" dur="1.8518518518518516s"
                            begin="-0.312s"></animateTransform>
        </circle>
        <circle cx="27.476" cy="79.354" :fill="fill" r="4">
          <animateTransform attributeName="transform" type="rotate" calcMode="spline" values="0 50 50;360 50 50"
                            times="0;1" keySplines="0.5 0 0.5 1" repeatCount="indefinite" dur="1.8518518518518516s"
                            begin="-0.375s"></animateTransform>
        </circle>
        <circle cx="20.646" cy="72.524" :fill="fill" r="4">
          <animateTransform attributeName="transform" type="rotate" calcMode="spline" values="0 50 50;360 50 50"
                            times="0;1" keySplines="0.5 0 0.5 1" repeatCount="indefinite" dur="1.8518518518518516s"
                            begin="-0.437s"></animateTransform>
        </circle>
        <animateTransform attributeName="transform" type="rotate" calcMode="spline" values="0 50 50;0 50 50" times="0;1"
                          keySplines="0.5 0 0.5 1" repeatCount="indefinite"
                          dur="1.8518518518518516s"></animateTransform>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'LoadingComponent',

  props: {
    fill: {
      type: String,
      default: '#ffffff'
    },
    size: {
      type: String,
      default: '64'
    }

  }
}
</script>