<template>
  <div
      class="border-1 rounded-3xl border-[#ECEAE3] md:p-6 bg-white h-[calc(100vh-100px)] md:h-[calc(100vh-140px)] w-full mx-auto no-scrollbar">
    <h1 class=" font-bold py-1 px-1 flex items-center">
    </h1>

    <search-component :disabled="isDisabled" class="mb-4 mx-4 md:mx-0"></search-component>

    <div class="md:h-[calc(100vh-300px)] h-[calc(100vh-240px)] overflow-y-scroll no-scrollbar">
      <div v-for="(filter) in filters" :key="filter.id">
        <filter-item :filter="filter" :disabled="isDisabled"></filter-item>
      </div>
    </div>
  </div>
</template>

<script>
import FilterItem from '@/views/library/components/FilterItem.vue';
import SearchComponent from "@/components/SearchComponent.vue";

export default {
  name: 'FilterComponent',
  components: {
    SearchComponent,
    FilterItem
  },
  props: {
    filters: {
      required: true,
      type: Array
    },
    isDisabled: {
      required: false,
      type: Boolean,
      default: false
    }
  }
}
</script>
