
export default {
    install(Vue){
        Vue.mixin({
            methods: {
                navigateTo(event, path, blank = false) {
                    if (event?.ctrlKey || event?.metaKey || event?.button === 1 || blank) {
                        let routeData = this.$router.resolve(path);
                        window.open(routeData.href, '_blank');
                    } else {
                        this.$router.push(path);
                    }
                },
            }
        })
    }
}