<template>
  <div v-if="useLibraryStore.openBookModal" id="popup-modal" tabindex="-1"
       class="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm"
       @click="closeModal">
    <div class="relative p-4 w-full max-w-4xl h-[calc(99vh)]" @click.stop>
      <div class="relative bg-white rounded-lg shadow overflow-auto max-h-full">

        <div class="sticky top-0 right-0 z-50">
          <button type="button"
                  class="absolute right-5 top-4 flex items-center justify-center w-10 h-10 bg-white rounded-full shadow-md text-pink-500 hover:bg-pink-100 border-2 border-white"
                  @click="closeModal">
            <svg class="w-6 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
            </svg>
          </button>
        </div>

        <div class="p-4 md:p-5 text-center">
          <vue-pdf-embed class="max-h-screen" :source="useLibraryStore.file"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed';
import {libraryStore} from "@/views/library/stores/library.store";

export default {
  components: {
    VuePdfEmbed,
  },
  setup() {
    const useLibraryStore = libraryStore();
    return {
      useLibraryStore
    };
  },
  methods: {
    /**
     * Closes the modal by setting the openBookModal property to false.
     */
    closeModal() {
      this.useLibraryStore.openBookModal = false;
    },
  },
};
</script>