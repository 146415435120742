<template>
  <div v-if="useCategoryStore.showEditCategoryModal"
       class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto z-50 justify-center items-center"
       @click.self="closeModal">
    <div class="relative top-20 mx-auto p-5 border w-full md:w-1/3 shadow-lg rounded-md bg-white">
      <div class="absolute -top-4 -right-4 transform">
        <button @click="closeModal"
                class="flex items-center justify-center w-8 h-8 bg-white rounded-full shadow-md text-pink-500 hover:bg-pink-100 border-2 border-white">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <h3 class="text-xl leading-6 font-medium text-gray-900">
        {{ useCategoryStore.editMode ? $t('edit category').capitalize() : $t('create category').capitalize() }}
      </h3>
      <hr class="px-2 mb-4">
      <form @submit.prevent="submitCategory">
        <div class="mb-4">
          <input-component
              input-type="text"
              placeholder="main category name"
              v-model="useCategoryStore.form.name"
              inputColor="primary"
              label="main category"
              required
              class="mb-2"
          />
          <template v-for="(subcategory, index) in useCategoryStore.form.subcategories" :key="index">
            <div class="relative mt-4 flex items-center">
              <div class="flex-shrink-0 mr-2 -rotate-45">
                <icon-component icon="fi fi-rr-angle-left" class="text-gray-400 text-2xl"/>
              </div>
              <div class="bg-gray-50 p-2 rounded-lg flex-grow">
                <input-component
                    input-type="text"
                    :placeholder="'subcategory name'"
                    v-model="subcategory.name"
                    inputColor="primary"
                    label="subcategory"
                    required
                />
                <div class="grid grid-cols-3 gap-2 items-center">
                  <div>
                    <label class="block text-gray-700 text-sm font-bold mb-1">
                      {{ $t('text color').capitalize() }}
                    </label>
                    <input type="color" v-model="subcategory.text_color" class="w-full h-7 p-0 border rounded"/>
                  </div>
                  <div>
                    <label class="block text-gray-700 text-sm font-bold mb-1">
                      {{ $t('background color').capitalize() }}
                    </label>
                    <input type="color" v-model="subcategory.background_color" class="w-full h-7 p-0 border rounded"/>
                  </div>
                  <div class="text-right">
                    <label class="block text-gray-700 text-sm font-bold mb-1">
                      {{ $t('example').capitalize() }}
                    </label>
                    <badge-component
                        :badgeText="subcategory.name"
                        :badgeColor="subcategory.background_color"
                        :textColor="subcategory.text_color"
                        size="sm"
                    />
                  </div>
                </div>
              </div>
              <div class="flex-shrink-0 ml-4">
                <button @click.prevent="removeSubcategory(index, subcategory)"
                        class="text-xl text-red-400 hover:text-red-500">
                  <i class="fi fi-sr-minus-circle"></i>
                </button>
              </div>
            </div>
          </template>
          <button @click.prevent="addSubcategory"
                  class="text-green-400 hover:text-white transition transition-duration-200 hover:bg-green-500 text-sm px-4 py-2 mt-2 rounded-lg border border-green-400 hover:border-green-500">
            {{ $t('add subcategory').capitalize() }}
          </button>
        </div>
        <div class="mb-4">
          <button-component
              :buttonText="useCategoryStore.editMode ? 'Update' : 'Submit'"
              buttonColor="action"
              :is-loading="useCategoryStore.editCategoriesLoading"
              class="!w-full"
              prefix-icon="fi fi-rr-paper-plane-top"/>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
import InputComponent from "@/components/forms/InputComponent.vue";
import BadgeComponent from "@/components/forms/BadgeComponent.vue";
import {categoryStore} from "@/views/categories/stores/category.store";
import IconComponent from "@/components/forms/IconComponent.vue";

export default {
  name: 'CreateCategoryComponent',
  components: {IconComponent, ButtonComponent, InputComponent, BadgeComponent},
  setup() {
    const useCategoryStore = categoryStore();

    return {
      useCategoryStore
    };
  },
  methods: {
    /**
     * Submits the category form.
     */
    submitCategory() {
      if (this.useCategoryStore.editMode) {
        this.useCategoryStore.updateCategory();
      } else {
        this.useCategoryStore.createCategory();
      }
    },
    /**
     * Adds a new subcategory to the form.
     */
    addSubcategory() {
      this.useCategoryStore.form.subcategories.push({name: '', text_color: '#000000', background_color: '#ffffff'});
    },
    /**
     * Removes a subcategory from the form.
     * @param {Number} index - The index of the subcategory to remove.
     * @param {Object} subcategory - The subcategory object to remove.
     */
    removeSubcategory(index, subcategory) {
      if (subcategory.id) {
        this.useCategoryStore.subcategoriesToDelete.push(subcategory.id);
      }
      this.useCategoryStore.form.subcategories.splice(index, 1);
    },
    /**
     * Closes the modal.
     */
    closeModal() {
      this.useCategoryStore.showEditCategoryModal = false;
    }
  }
};
</script>