<template>
  <div class="rounded-full border border-gray-300" :class="setSize" :style="{ backgroundColor: background_color }"></div>
</template>

<script>
export default {
  name: 'CircleComponent',
  props: {
    background_color: {
      type: String,
      default: '#FFFFFF'
    },
    size: {
      type: String,
      default: 'sm'
    }
  },
  computed: {
    setSize() {
      switch (this.size) {
        case 'sm':
          return 'w-4 h-4';
        case 'lg':
          return 'w-10 h-10';
        default:
          return 'w-6 h-6';
      }
    }
  }
};
</script>