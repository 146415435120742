<template>
  <bug-list-component/>
</template>

<script>
import {analyticsStore} from "@/views/analytics/stores/analytics.store.js";
import BugListComponent from "@/views/analytics/components/BugListComponent.vue";

export default {

  name: 'AnalyticsView',
  components: {BugListComponent},
  setup() {
    const useAnalyticsStore = analyticsStore();

    return {
      useAnalyticsStore
    }
  }
}
</script>