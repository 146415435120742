import {defineStore} from "pinia";
import authService from "@/views/auth/services/auth.service";
import {appStore} from "@/views/app.store";
import libraryService from "@/views/library/services/library.service";
import router from "@/router";
import {i18n} from '@/i18n';
import {canUseCookies, getEmailVerification, hasDeniedCookies, hasEssentialCookies} from "@/auth";
import {showSuccessAlert} from "@/helpers/sweetAlert";
import {libraryStore} from "@/views/library/stores/library.store";

export const authStore = defineStore('authStore', {
    state: () => ({
        emailVerify: {
            status: 'not_verified',
            password: '',
            password_confirmation: '',
            email: ''
        },
        form: {
            email: '',
            password: '',
        },
        formPasswordReset: {
            email: '',
            password: '',
            password_confirmation: '',
            token: '',
        },
        demoForm: {
            selectedDescribe: '',
            firstname: '',
            lastname: '',
            email: '',
            schoolName: '',
            elseDescribe: '',
            url: window.location.origin
        },
        permissions: [],
        user: {},
        role: {},
        translatedRole: '',
        isLoading: false,
        userIsEmailVerified: false,
        workspace: {}
    }),
    getters: {
        userAvatarUrl() {
            return "https://ui-avatars.com/api/?size=35&name=" + this.user.first_name + "+" + this.user.last_name + "&rounded=true&background=51AFE3&color=fff";
        },
    },
    actions: {
        /**
         * Sends a password reset email to the user.
         * @returns {Promise<void>}
         */
        async forgotPassword() {
            let form = {
                email: this.formPasswordReset.email
            }
            await authService.forgotPassword(form).then((response) => {
                let useAppStore = appStore();
                useAppStore.setError(response.data.message, 'success');
                router.push({path: '/login'})
            }).catch((error) => {
                console.error("Error in sending password reset email:", error);
            });
        },

        /**
         * Resets the user's password.
         * @returns {Promise<void>}
         */
        async resetPassword() {
            let useAppStore = appStore();

            this.formPasswordReset.token = window.location.pathname.split('/').pop();
            await authService.resetPassword(this.formPasswordReset).then((response) => {
                useAppStore.setError(response.data.message, 'success');
                router.push({path: '/login'})
            }).catch((error) => {
                console.error("Error in sending password reset email:", error);
            });
        },

        /**
         * Checks the email verification status.
         * @returns {Promise<void>}
         */
        async checkEmail() {
            let token = window.location.pathname.split('/').pop();
            await authService.checkEmail(token).then((response) => {
                let status = response.data.data.status;
                this.emailVerify.status = response.data.data.status
                this.emailVerify.email = response.data.data.email

                if (status === 'email_not_verified_password_set') {
                    this.confirmEmail()
                    this.getProfile()
                    if (getEmailVerification()) {
                        this.logout()
                    }
                }
            }).catch(() => {
                this.emailVerify.status = 'token_invalid';
            });
        },

        /**
         * Confirms the user's email.
         * @returns {Promise<void>}
         */
        async confirmEmail() {
            let token = window.location.pathname.split('/').pop();
            let data = {
                token: token,
                password: this.emailVerify.password,
                password_confirmation: this.emailVerify.password_confirmation
            };
            await authService.confirmEmail(data).then(async (response) => {
                this.form.email = this.emailVerify.email
                this.form.password = this.emailVerify.password
                this.emailVerify.status = response.data.message

                if (this.form.email && this.form.password) {
                    let useAppStore = appStore();
                    useAppStore.setError('email confirmed', 'success');
                    this.userIsEmailVerified = true
                    await this.login();
                    router.push({path: '/'})
                }
            }).catch((error) => {
                console.error("error in confirming email:", error);
            });
        },

        /**
         * Sends an email confirmation.
         * @returns {Promise<void>}
         */
        async sendEmailConfirm() {
            let useAppStore = appStore();
            let form = {email: this.form.email}

            await authService.sendEmailConfirm(form).then((response) => {
                useAppStore.setError(response.data.message, 'success');
            }).catch((error) => {
                console.error("Error in sending email confirmation:", error);
            });
        },

        /**
         * Logs in the user.
         * @returns {Promise<void>}
         */
        async login() {
            if (canUseCookies() || hasEssentialCookies()) {
                await authService.postLogin(this.form).then(response => {
                    if (response.data.user.email_verified_at !== null) {
                        this.userIsEmailVerified = true
                    } else {
                        this.userIsEmailVerified = false
                    }
                    const accessToken = response.data.access_token;
                    this.user = response.data.user;
                    this.workspace = response.data.workspace;

                    const user = JSON.stringify(response.data.user);
                    this.permissions = response.data.permissions;

                    const expiryDate = new Date();
                    expiryDate.setTime(expiryDate.getTime() + (24 * 60 * 60 * 1000));
                    const expires = "expires=" + expiryDate.toUTCString();

                    if (response.data.workspace) {
                        document.cookie = "workspace=" + response.data.workspace + ";" + expires + ";path=/";
                    }
                    document.cookie = "accessToken=" + accessToken + ";" + expires + ";path=/";
                    document.cookie = "user=" + user + ";" + expires + ";path=/";

                    if (!this.user.email_verified_at) {
                        return router.push({path: '/email/verify'})
                    }
                }).catch(error => {
                    console.error('Login failed', error);
                });
            } else if (hasDeniedCookies()) {
                alert('You have denied cookies. To login, please enable cookies again.');
            } else {
                alert('You must accept cookies to log in.');
            }
        },

        /**
         * Sets the workspace cookie.
         * @param {string} workspace - The workspace to set in the cookie.
         * @returns {Promise<void>}
         */
        async setWorkspaceCookie(workspace) {
            const expiryDate = new Date();
            expiryDate.setTime(expiryDate.getTime() + (24 * 60 * 60 * 1000));
            const expires = "expires=" + expiryDate.toUTCString();

            document.cookie = "workspace=" + workspace + ";" + expires + ";path=/";
        },

        /**
         * Logs out the user.
         * @param {number|null} statusCode - The status code to check before logging out.
         * @returns {Promise<void>}
         */
        async logout(statusCode = null) {
            try {
                if (statusCode !== 401) {
                    await authService.postLogout();
                }

                document.cookie = 'accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                document.cookie = 'user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                document.cookie = 'workspace=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

                let useLibraryStore = libraryStore();
                useLibraryStore.lastFetched = null
                useLibraryStore.pagination = {
                    pagesLoaded: 0,
                    totalPages: 1,
                    currentPage: 1,
                    perPage: 12
                }

                useLibraryStore.books = []
                useLibraryStore.dashboard_books = []
                useLibraryStore.filters = []
                useLibraryStore.categories = []

                router.push({path: '/login'})
            } catch (error) {
                console.error('Logout failed:', error);
            }
        },

        /**
         * Retrieves the user's profile.
         * @returns {Promise<void>}
         */
        async getProfile() {
            try {
                const response = await authService.getProfile();
                const userData = response.data.data;

                if (userData.user.email_verified_at !== null) {
                    this.userIsEmailVerified = true
                }
                this.user = userData?.user;
                this.permissions = userData?.permissions;
                if (userData?.workspace?.image_name && userData?.workspace?.image) {
                    await this.getWorkspaceLogoImage(userData?.workspace?.image_name);
                }

                this.workspace = userData?.workspace;
                this.role = userData?.user?.roles[0];
                this.translatedRole = i18n.t(userData?.user?.roles[0]?.name)
                document.title = this.workspace.name

            } catch (error) {
                console.error('Error in getting user details', error);
            }
        },

        /**
         * Retrieves the workspace logo image.
         * @param {string} image_name - The name of the image to retrieve.
         * @returns {Promise<void>}
         */
        async getWorkspaceLogoImage(image_name) {
            await libraryService.getBookImage(image_name).then(response => {
                let ImageBase64 = response.data.data;
                this.image = ImageBase64;
            });
        },

        /**
         * Converts a base64 string to a Blob.
         * @param {string} base64 - The base64 string to convert.
         * @returns {Blob} The resulting Blob.
         */
        base64ToBlob(base64) {
            const binaryString = window.atob(base64);
            const bytes = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            return new Blob([bytes], {type: 'image/jpeg'});
        },

        /**
         * Checks if the user has a specific permission.
         * @param {string} permission - The permission to check.
         * @returns {Promise<boolean>} True if the user has the permission, false otherwise.
         */
        async checkPermission(permission) {
            if (!this.permissions.length) {
                await this.getProfile();
            }
            return this.permissions.includes(permission);
        },

        /**
         * Creates a demo form.
         * @returns {Promise<void>}
         */
        async createDemoForm() {
            let apiForm = {
                "function": this.demoForm.selectedDescribe === 'else' ? this.demoForm.elseDescribe : this.demoForm.selectedDescribe,
                "first_name": this.demoForm.firstname,
                "last_name": this.demoForm.lastname,
                "email": this.demoForm.email,
                "school_name": this.demoForm.schoolName,
                "url": this.demoForm.url
            }

            try {
                await authService.postDemoForm(apiForm);
                this.isLoading = false;
                showSuccessAlert('account created', 'check your mailbox to verify your account');
                router.push({path: '/login'});

                this.demoForm = {
                    selectedDescribe: '',
                    firstname: '',
                    lastname: '',
                    email: '',
                    schoolName: '',
                    elseDescribe: '',
                    url: window.location.origin
                };
            } catch (error) {
                this.isLoading = false;
                throw error.response?.data;
            }
        }
    }
});