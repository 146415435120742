<template>
  <div class="h-20 bg-default px-2">
    <div class="flex justify-between items-center h-full relative">
      <nav class="flex w-full overflow-auto justify-evenly">
        <div
            v-for="(navItem) in visibleNavItems"
            :key="navItem.title"
            class="text-center">
          <RouterLink
              v-if="useAuthStore.permissions?.includes(navItem.permission) || !navItem.permission"
              :to="navItem.to"
              @click.prevent="useAppStore.isMobile ? useAppStore.showSideNavigation = !useAppStore.showSideNavigation : ''"
              class="flex items-center text-gray-700 transition-all"
              :class="{ 'text-selected': $route.path === navItem.to }">
            <div class="rounded-full transition duration-300 text-center">
              <div class="flex items-center justify-center h-7 w-7 mx-auto"
                   :class="{ 'text-selected': $route.path === navItem.to, 'rounded-full bg-white mx-auto my-auto': $route.path === navItem.to }">
                <i :class="[navItem.icon, 'transition-colors duration-300']"/>
              </div>
              <div
                  :class="{ 'text-selected': $route.path === navItem.to }"
                  class="text-[10px]">
                {{ $t(navItem.title.lowercase()).capitalize() }}
              </div>
            </div>
          </RouterLink>
        </div>

        <div class="relative text-center">
          <div
              class="flex items-center cursor-pointer text-gray-700 transition-all"
              @click="toggleMoreMenu"
          >
            <div class="rounded-full transition duration-300 text-center">
              <div class="flex items-center justify-center h-7 w-7 mx-auto">
                <i class="fi fi-rr-menu-dots transition-colors duration-300"></i>
              </div>
              <div class="text-[10px]">
                {{ $t("more").capitalize() }}
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div
          v-if="isMoreMenuOpen"
          class="absolute bg-white border border-gray-300 shadow-lg rounded-md p-2 mt-2 right-2 bottom-[90px] z-50 w-48"
      >
        <div
            v-for="navItem in moreNavItems"
            :key="navItem.title"
            class="py-1"
        >
          <RouterLink
              v-if="useAuthStore.permissions?.includes(navItem.permission) || !navItem.permission"
              :to="navItem.to"
              class="block text-gray-700 hover:text-selected"
              :class="{ 'text-selected': $route.path === navItem.to }"
              @click="toggleMoreMenu"
          >
            {{ $t(navItem.title.lowercase()).capitalize() }}
          </RouterLink>
        </div>
        <div class="py-1">
          <RouterLink
              to=""
              @click.prevent="handleLogout"
              class="block text-gray-700 hover:text-selected"
              :class="{
              'text-selected': $route.path === '/logout',
            }"
          >
            {{ $t('logout').capitalize() }}
          </RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {authStore} from "@/views/auth/stores/auth.store";
import {appStore} from "@/views/app.store";
import {useRouter} from "vue-router";

export default {
  name: "MobileNavigation",
  setup() {
    const useAuthStore = authStore();
    const useAppStore = appStore();
    const router = useRouter();
    return {
      useAuthStore,
      router,
      useAppStore,
    };
  },
  data() {
    return {
      isExpanded: true,
      isMoreMenuOpen: false,
      navItems: [
        {name: "Home", to: "/", title: "Dashboard", icon: "fi fi-rr-home"},
        {
          name: "Lessons",
          to: "/library",
          title: "Library",
          icon: "fi fi-rr-book-alt",
          permission: "books_view",
        },
        {
          name: "Class Weeks",
          to: "/agenda",
          title: "Class Programs",
          icon: "fi fi-rr-calendar-day",
          permission: "class_weeks_view",
        },
        {
          name: "Users",
          to: "/users",
          title: "Users",
          icon: "fi fi-rs-users",
          permission: "users_view",
        },
        {
          name: "Categories",
          to: "/categories",
          title: "Categories",
          icon: "fi fi-rr-layers",
          permission: "categories_view",
        },
        {
          name: "Analytics",
          to: "/analytics",
          title: "Analytics",
          icon: "fi fi-rr-chart-histogram",
          permission: "analytics_view",
        },
        {
          name: "Settings",
          to: "/settings",
          title: "Settings",
          icon: "fi fi-rr-settings",
          permission: "workspaces_update_own",
        },
      ],
    };
  },
  computed: {
    visibleNavItems() {
      return this.navItems.slice(0, 3);
    },
    moreNavItems() {
      return this.navItems.slice(3);
    },
  },
  methods: {
    /**
     * Toggles the visibility of the "More" menu.
     */
    toggleMoreMenu() {
      this.isMoreMenuOpen = !this.isMoreMenuOpen;
    },
    /**
     * Handles the logout process.
     * Attempts to log out the user and logs an error if the process fails.
     */
    async handleLogout() {
      try {
        await this.useAuthStore.logout();
      } catch (error) {
        console.error("Logout failed:", error);
      }
    },
  },
};
</script>