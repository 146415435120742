<template>
  <div class="">
    <show-book-component :book="book"/>
  </div>
</template>

<script>
import ShowBookComponent from "@/views/library/components/ShowBookComponent.vue";
import {libraryStore} from "@/views/library/stores/library.store";

export default {
  name: 'ShowBookView',
  components: {ShowBookComponent},

  setup() {
    const useLibraryStore = libraryStore();
    return {
      useLibraryStore
    };
  },

  data() {
    return {
      book: {}
    };
  },

  mounted() {
    this.fetchBook();
  },

  methods: {
    /**
     * Fetches the book details based on the UUID from the route parameters.
     */
    async fetchBook() {
      await this.useLibraryStore.fetchBook(this.$route.params.uuid);
      this.book = this.useLibraryStore.book;
    }
  }
};
</script>