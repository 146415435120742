<template>
  <div class="grid grid-cols-[auto,1fr] gap-4 items-start">
    <div
        class="relative drop-shadow lg:rounded-[20px] aspect-[2/3] lg:w-[5vw] md:w-[5vw] w-[14vw]"
        :class="{ 'cursor-not-allowed': isLocked }"
        @click="handleContainerClick"
    >
      <button
          v-if="!hideDropdown && useAuthStore?.user?.roles?.[0]?.name === 'reseller_admin'"
          @click.stop="toggleDropdown"
          class="bg-white absolute right-2 top-2 h-5 w-5 rounded-full"
      >
        <icon-component icon="fi fi-br-menu-dots text-black text-sm"></icon-component>
      </button>

      <div
          v-if="dropdownVisible && (useAuthStore.permissions?.includes(updatePermission) || useAuthStore.permissions?.includes(deletePermission))"
          class="absolute top-8 right-2 bg-white rounded shadow-lg p-2 z-50 flex flex-col gap-2"
          @click.stop
      >
        <button
            v-if="useAuthStore.permissions?.includes(updatePermission)"
            @click="onEditClick"
            class="flex items-center justify-center w-10 h-10 rounded-full hover:bg-gray-100"
        >
          <i class="fa fa-pen text-gray-600 text-lg"></i>
        </button>

        <button
            v-if="useAuthStore.permissions?.includes(deletePermission)"
            @click="onDeleteClick"
            class="flex items-center justify-center w-10 h-10 rounded-full hover:bg-gray-100"
        >
          <i class="fa fa-trash text-gray-600 text-lg"></i>
        </button>
      </div>

      <div
          v-if="isLocked"
          class="absolute inset-0 z-10 bg-black bg-opacity-50 flex flex-col items-center justify-center rounded-2xl"
      >
        <icon-component icon="fi fi-sr-lock text-white md:text-xl text-md"></icon-component>
        <p class="text-white text-center lg:text-[10px] text-[8px] px-2">{{ $t("more lessons?").capitalize() }}</p>
        <ButtonComponent
            @click="showPromotionModal"
            :always-show-text="true"
            button-color="cta"
            class="md:text-[8px] text-[6px]"
            button-text="unlock"
            size="small"
            :disabled="!!useAuthStore?.user?.request_upgrade"
        ></ButtonComponent>
      </div>

      <img
          v-if="image"
          class="h-full w-full lg:rounded-[20px] rounded-lg object-cover transition-opacity duration-1000 ease-in-out"
          :class="{ 'opacity-0': !imageLoaded, 'opacity-100': imageLoaded }"
          :src="image"
          alt=""
          @load="onImageLoad"
      />
    </div>

    <div class="min-w-0">
      <div v-if="title"
           class="font-black truncate"
           :class="'text-sm lg:text-' + (title.length > 20 ? 'xs' : 'sm')"
           :title="title">
        {{ title }}
      </div>
      <SkeletonLoader v-else/>

      <div class="flex flex-col">
        <div
            v-for="(category, index) in categories"
            :key="category.id"
            class="text-xs lg:text-sm truncate"
            :class="[
              index === 0 ? 'text-[#d43a65] mb-1' : 'text-black',
            ]"
        >
          {{ category.name }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {authStore} from "@/views/auth/stores/auth.store.js";
import {appStore} from "@/views/app.store.js";
import IconComponent from "@/components/forms/IconComponent.vue";
import ButtonComponent from "@/components/forms/ButtonComponent.vue";

export default {
  name: "CardComponent",
  components: {ButtonComponent, IconComponent},

  props: {
    updatePermission: {type: String, required: false},
    deletePermission: {type: String, required: false},
    title: {type: String},
    image: {required: false},
    categories: {type: Array},
    hideDropdown: {type: Boolean, default: false},
    isLocked: {type: Boolean, default: false}
  },
  setup() {
    const useAuthStore = authStore();
    const useAppStore = appStore();

    return {
      useAuthStore,
      useAppStore,
    };
  },
  data() {
    return {
      dropdownVisible: false,
      imageLoaded: false,
    };
  },
  methods: {
    /**
     * Handles the click event on the container.
     * If the card is not locked, it triggers the onContainerClick method.
     */
    handleContainerClick() {
      if (!this.isLocked) {
        this.onContainerClick();
      }
    },
    /**
     * Toggles the visibility of the dropdown menu.
     * If the dropdown is not hidden, it toggles the dropdownVisible state.
     */
    toggleDropdown() {
      if (!this.hideDropdown) {
        this.dropdownVisible = !this.dropdownVisible;
      }
    },
    /**
     * Emits the "show" event if the dropdown is not visible.
     */
    onContainerClick() {
      if (!this.dropdownVisible) {
        this.$emit("show");
      }
    },
    /**
     * Handles the click event on the edit button.
     * Hides the dropdown and emits the "edit" event.
     */
    onEditClick() {
      this.dropdownVisible = false;
      this.$emit("edit");
    },
    /**
     * Handles the click event on the delete button.
     * Hides the dropdown and emits the "delete" event with the title as a parameter.
     */
    onDeleteClick() {
      this.dropdownVisible = false;
      this.$emit("delete", this.title);
    },
    /**
     * Sets the imageLoaded state to true when the image is loaded.
     */
    onImageLoad() {
      this.imageLoaded = true;
    },
    /**
     * Shows the promotion modal if the user has not requested an upgrade.
     */
    showPromotionModal() {
      this.useAppStore.showUpgradeFormModal = !this.useAuthStore?.user?.request_upgrade;
    },
  },
};
</script>