import {deleteData, getData, patchData, postData} from '@/helpers/api.helper';

const baseUrl = process.env.VUE_APP_API_URL;

export default {

    async getAllUsers() {
        return getData(`${baseUrl}/api/users`, null, {}, true);
    },
    async postUser(data) {
        return postData(`${baseUrl}/api/users/create`, data, null, {}, true)
    },
    async postWorkspace(data) {
        return postData(`${baseUrl}/api/workspaces/create`, data, null, {}, true)
    },
    async updateWorkspace(data) {
        return patchData(`${baseUrl}/api/workspaces/update`, data, null, {}, true)
    },
    async deleteWorkspace(data) {
        return deleteData(`${baseUrl}/api/workspaces/delete`, data, null, {}, true);
    },
    async updateUserRole(data) {
        return patchData(`${baseUrl}/api/users/update/role`, data, null, {}, true)
    },
    async denyUserRequest(data) {
        return postData(`${baseUrl}/api/users/request/deny`, data, null, {}, true)
    },
    async postUserBooks(data) {
        return postData(`${baseUrl}/api/users/books/create`, data, null, {}, true)
    },
    async postUserPrograms(data) {
        return postData(`${baseUrl}/api/users/programs/create`, data, null, {}, true)
    },
    async uploadLogo(data) {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        return postData(`${baseUrl}/api/workspaces/image/upload`, data, null, config, true);
    },
    async getRoles() {
        return getData(`${baseUrl}/api/roles`, null, {}, true);
    },
    async deleteUser(data) {
        return deleteData(`${baseUrl}/api/users/delete`, data, null, {}, true)

    },
    async sendUserUpgrade(data) {
        return postData(`${baseUrl}/api/users/request/upgrade`, data, null, {}, true);

    }
}