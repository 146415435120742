<template>
  <div class="p-2 w-full overflow-x-auto md:h-[calc(100vh-140px)] relative">
    <div class="flex justify-between items-center mb-4">
      <div class="font-medium text-[#d43a65] mb-4">
        <div class="py-3 text-lg lg:text-3xl text-black font-bold">
          {{ $t('programs').capitalize() }}
        </div>
        <p class="text-xs lg:text-lg">{{ $t('amount of programs').capitalize() }}: <span>{{ programs.length }}</span>
        </p>
      </div>
      <div>
        <button-component class="hidden md:block"
                          :disabled="useAgendaStore.books.length <= 0 || useAgendaStore.isLoading"
                          buttonText="add program"
                          buttonColor="action" v-if="useAuthStore.permissions.includes('class_weeks_create')"
                          @click="createProgram()"/>
      </div>
    </div>
    <div
        class="border-2 rounded-xl lg:rounded-3xl lg:text-lg text-xs border-[#ECEAE3] table-responsive shadow-sm lg:p-12 p-6 flex-1 overflow-y-auto md:h-[calc(60vh-20px)] no-scrollbar">
      <table class="w-full border-collapse">
        <thead>
        <tr class="border-b-2 text-left">
          <th class="text-gray-600">{{ $t('title').capitalize() }}</th>
          <th class="md:block hidden text-gray-600">{{ $t('description').capitalize() }}</th>
          <th class="text-gray-600">{{ $t('amount of lessons').capitalize() }}</th>
          <th class="text-gray-600">{{ $t('actions').capitalize() }}</th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="program in programs"
            :key="program.title"
            @click.prevent="openProgram($event, program)"
        >
          <td class="py-4 hover:cursor-pointer hover:underline">{{ program.title }}</td>
          <td class="md:block hidden py-4">{{ program.description }}</td>
          <td class="py-4">{{ program.books.length }}</td>
          <td class="py-4 flex gap-2 justify-content-end">
            <button @click.prevent="deleteProgram(program.uuid)"
                    class="inline-flex items-center text-red-500 hover:text-red-600 transition duration-150"
                    v-if="useAuthStore.permissions?.includes('class_weeks_update')">
              <i class="fas fa-trash-alt"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-if="!useAgendaStore.showEditProgramModal" class="md:hidden mb-20 fixed bottom-4 left-4 right-4 z-40">
      <button-component :disabled="useAgendaStore.books.length <= 0 || useAgendaStore.isLoading"
                        buttonText="add program" buttonColor="action"
                        :always-show-text="true"
                        :ghost="true"
                        class="mb-2 py-1 rounded-full w-full max-w-xs mx-auto text-md shadow-lg flex items-center justify-center"
                        v-if="useAuthStore.permissions?.includes('class_weeks_create')" @click="createProgram()"/>
    </div>
  </div>
</template>

<script>
import {agendaStore} from "@/views/agenda/stores/agenda.store.js";
import {authStore} from "@/views/auth/stores/auth.store.js";
import ButtonComponent from "@/components/forms/ButtonComponent.vue";

export default {
  name: "OverviewAgendaComponent",
  components: {ButtonComponent},

  setup() {
    const useAgendaStore = agendaStore();
    const useAuthStore = authStore();

    return {
      useAgendaStore,
      useAuthStore,
    };
  },

  props: {
    programs: {
      required: true,
      type: Array,
    },
  },

  methods: {
    /**
     * Opens the selected program.
     * @param {Event} $e - The event object.
     * @param {Object} program - The program to open.
     */
    openProgram($e, program) {
      this.useAgendaStore.program = program;
      this.navigateTo($e, "/agenda/" + program.uuid);
    },
    /**
     * Creates a new program.
     * Sets the showEditProgramModal to true and resets the form.
     */
    createProgram() {
      this.useAgendaStore.showEditProgramModal = true;
      this.useAgendaStore.resetForm();
      this.useAgendaStore.editMode = false;
    },
    /**
     * Deletes the selected program.
     * @param {String} programUuid - The UUID of the program to delete.
     */
    deleteProgram(programUuid) {
      this.useAgendaStore.deleteProgram(programUuid);
    },
  },
};
</script>