<template>
  <div v-if="useAppStore.showConsent"
       class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div class="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full text-center relative">
      <button class="absolute top-2 right-2 text-gray-400 hover:text-gray-600" @click="useAppStore.refuseCookies">
        &times;
      </button>
      <div class="flex justify-center mb-4"></div>
      <h2 class="text-xl font-semibold mb-2">
        <icon-component icon="fi fi-rr-cookie" class="block text-2xl"></icon-component>
        {{ $t('cookie disclaimer!').capitalize() }}
      </h2>
      <p class="text-gray-600 text-sm mb-6">
        {{ $t('we use cookies to ensure you get the best experience.').capitalize() }}
      </p>
      <div class="flex justify-center space-x-4">
        <button class="px-2 py-1 text-sm bg-action text-white rounded-lg shadow hover:bg-opacity-90"
                @click="useAppStore.acceptCookies">
          {{ $t('accept all cookies').capitalize() }}
        </button>
        <button class="px-3 py-1 text-sm bg-action text-white rounded-lg shadow hover:bg-opacity-90"
                @click="useAppStore.giveEssentialCookies">
          {{ $t('give essential cookies').capitalize() }}
        </button>
      </div>
    </div>
  </div>
  <div v-else-if="useAppStore.cookiesDenied"
       class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <div class="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full text-center">
      <p class="mb-4">{{ $t('you have refused cookies').capitalize() }}</p>
      <button class="px-4 py-2 bg-action text-white rounded" @click="useAppStore.showConsentAgain">
        {{ $t('change decision').capitalize() }}
      </button>
    </div>
  </div>
</template>

<script>
import {appStore} from "@/views/app.store";
import IconComponent from "@/components/forms/IconComponent.vue";

export default {
  name: 'CookieConsent',
  components: {IconComponent},
  setup() {
    let useAppStore = appStore();

    return {
      useAppStore
    };
  },
  props: {
    showConsent: {
      type: Boolean,
      default: false,
    },
    cookiesDenied: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.checkCookieConsent();
  },
  methods: {
    /**
     * Checks the cookie consent status and updates the store accordingly.
     */
    checkCookieConsent() {
      const consent = this.getCookie('cookies_accepted');
      if (consent === null) {
        this.useAppStore.showConsent = true;
      } else if (consent === 'false') {
        this.useAppStore.cookiesDenied = true;
      }
    },
    /**
     * Retrieves the value of a specified cookie.
     * @param {string} name - The name of the cookie to retrieve.
     * @returns {string|null} The value of the cookie, or null if not found.
     */
    getCookie(name) {
      const nameEQ = name + "=";
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
  },
};
</script>