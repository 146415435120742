import {getData, postData} from '@/helpers/api.helper';

const baseUrl = process.env.VUE_APP_API_URL;

export default {
    async forgotPassword(form) {
        return postData(`${baseUrl}/api/users/forget-password`, form, null, {}, false);
    },
    async resetPassword(form) {
        return postData(`${baseUrl}/api/users/reset-password`, form, null, {}, false);
    },
    async postLogin(data) {
        return postData(`${baseUrl}/api/users/login`, data, null, {}, false);

    },
    async postLogout() {

        return postData(`${baseUrl}/api/users/logout`, {}, null, {}, true);
    },
    async postDemoForm(data) {

        return postData(`${baseUrl}/api/users/demo/create`, data, null, {}, false);
    },

    async confirmEmail(data) {

        return postData(`${baseUrl}/api/users/email-verify`, data, null, {}, false);
    },

    async checkEmail(token) {
        return getData(`${baseUrl}/api/users/email-verify/${token}`, null, {}, false);
    },

    async getProfile() {
        return getData(`${baseUrl}/api/users/profile`, null, {}, true);
    },

    async sendEmailConfirm(data) {
        return postData(`${baseUrl}/api/users/email-verify/send`, data, null, {}, true);
    },

}