<template>
  <router-view>
    <div class="p-6">
      <div v-if="!useLibraryStore.showEditBookModal" class="md:hidden mb-20 fixed bottom-4 left-4 right-4 z-40">
        <button-component
            :disabled="useLibraryStore.categories.length <= 0 || useLibraryStore.isLoadingBooks"
            buttonText="create lesson"
            buttonColor="action"
            :always-show-text="true"
            :ghost="true"
            class="mb-2 py-1 rounded-full w-full max-w-xs mx-auto text-md shadow-lg flex items-center justify-center"
            v-if="useAuthStore.permissions?.includes('books_create')"
            @click="openCreateModal()"
        />
        <button-component
            @click="toggleFilter"
            class="py-1 rounded-full w-full max-w-xs mx-auto text-md shadow-lg flex items-center justify-center"
            button-text="filters"
            button-color="action"
            :always-show-text="true"
            prefix-icon="fi fi-rr-settings-sliders"
        >
        </button-component>
      </div>

      <overview-book-component class="flex flex-col md:col-span-7 col-span-9 md:mt-0" :libraries="libraries"/>
      <edit-book-view v-if="useLibraryStore.showEditBookModal"/>
    </div>

    <div v-if="showMobileFilter" class="fixed inset-0 bg-black bg-opacity-50 z-40 lg:hidden"
         @click="toggleFilter"></div>

    <div v-if="showMobileFilter" class="mobile-filter-panel fixed inset-0 z-50 lg:hidden">
      <div class="absolute inset-y-0 left-0 w-full bg-white mt-20 rounded-t-3xl animate-slide-in">
        <button-component
            prefix-icon="fi fi-rs-cross-small"
            buttonColor="cta"
            :ghost="true"
            @click="toggleFilter"
            class="ml-auto text-sm mt-2 mr-2"
        ></button-component>
        <filter-component :filters="useLibraryStore.filters"/>
      </div>
    </div>

  </router-view>
</template>

<script>
import EditBookView from "@/views/library/EditBookView.vue";
import OverviewBookComponent from "@/views/library/components/OverviewBookComponent.vue";
import {libraryStore} from "@/views/library/stores/library.store.js";
import {computed, ref} from 'vue';
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
import FilterComponent from "@/views/library/components/FilterComponent.vue";
import {authStore} from "@/views/auth/stores/auth.store";

export default {
  name: 'ShowAllLibraryView',
  components: {FilterComponent, ButtonComponent, OverviewBookComponent, EditBookView},

  setup() {
    const useLibraryStore = libraryStore();
    const showMobileFilter = ref(false);
    const useAuthStore = authStore();

    const libraries = computed(() => {
      return Array.isArray(useLibraryStore.books) ? useLibraryStore.books.map(book => ({
        uuid: book.uuid,
        bookName: book.name,
        categories: book.categories,
        description: book.description,
        image_name: book.image_name,
        is_demo: book.is_demo,
        image: book.image,
        updated_at: book.updated_at,
        showImage: true,
        views: book.views,
      })) : [];
    });

    const toggleFilter = () => {
      if (showMobileFilter.value) {
        const filterPanel = document.querySelector('.mobile-filter-panel');
        if (filterPanel) {
          filterPanel.classList.remove('animate-slide-in');
          filterPanel.classList.add('animate-slide-out');

          filterPanel.addEventListener('animationend', () => {
            showMobileFilter.value = false;
            filterPanel.classList.remove('animate-slide-out');
          }, {once: true});
        }
      } else {
        showMobileFilter.value = true;
      }
    };

    return {
      libraries,
      useLibraryStore,
      useAuthStore,
      showMobileFilter,
      toggleFilter,
    };
  },
  mounted() {
    this.getBooks();
  },
  methods: {
    /**
     * Opens the modal to create a new book.
     */
    async openCreateModal() {
      try {
        this.useLibraryStore.loading = true;
        this.useLibraryStore.resetForm();
        this.useLibraryStore.showEditBookModal = true;
        this.useLibraryStore.editMode = false;
      } catch (error) {
        console.error('Error opening create modal:', error);
      } finally {
        this.useLibraryStore.loading = false;
      }
    },

    /**
     * Fetches all books and categories from the store.
     */
    async getBooks() {
      await this.useLibraryStore.fetchAllCategories();
      let setFilters = [];
      for (let i = 0; i < this.useLibraryStore.categories.length; i++) {
        let category = {
          name: this.useLibraryStore.categories[i].name,
          value: this.useLibraryStore.categories[i].id,
          checked: false,
          filterProperties: {},
          filterType: 'parent',
          filter: []
        };
        if (this.useLibraryStore.categories[i].subcategories.length) {
          for (let j = 0; j < this.useLibraryStore.categories[i].subcategories.length; j++) {
            category.filter.push({
              filterType: 'child',
              checked: false,
              name: this.useLibraryStore.categories[i].subcategories[j].name,
              value: this.useLibraryStore.categories[i].subcategories[j].id,
            });
          }
        }
        setFilters.push(category);
      }
      this.useLibraryStore.filters = setFilters;

      this.useLibraryStore.queryFilter = 'per_page=' + this.useLibraryStore.pagination.perPage;

      await this.useLibraryStore.fetchAllBooks(1, null);
    }
  }
}
</script>