<template>
  <div
      :class="['mx-auto mb-4 border-0 rounded block', selectColors(inputColor).borderColor]">
    <div class="text-md" v-if="label">{{ $t(label.lowercase()).capitalize() }} <span v-if="required">*</span></div>
    <textarea
        class="p-6 placeholder-gray-600 w-full border-2 border-default rounded-xl"
        :class="selectColors(inputColor).borderColor"
        :placeholder="$t(placeholder.lowercase()).capitalize()"
        :value="modelValue"
        :rows="rows"
        :required="required"
        @input="$emit('update:modelValue', $event.target.value)"
    >
    </textarea>
  </div>
</template>

<script>
export default {
  name: 'TextareaComponent',
  emits: ['update:modelValue'],

  props: {
    modelValue: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String
    },
    inputColor: {
      type: String
    },
    rows: {
      type: Number,
      default: 2
    },
    label: {
      type: String,
      default: null,
      required: false
    },
    required: {
      type: Boolean,
      default: false
    },
  },

  methods: {
    selectColors(inputColor) {
      if (inputColor === 'primary') {
        return {
          borderColor: 'border-FLPrimary',
        }
      } else if (inputColor === 'secondary') {
        return {
          borderColor: 'border-FLsecondary',
        }
      } else {
        return {
          borderColor: 'border-gray-400',
        }
      }
    }
  }
}
</script>
