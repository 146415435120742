<template>
  <div class="relative">
    <input
        @input="handleInput"
        v-model="searchQuery"
        type="text"
        :disabled="disabled"
        :class="{
        'cursor-not-allowed': disabled,
        'bg-gray-200': disabled,
        'bg-white': !disabled,
      }"
        class="w-full h-10 pl-6 text-sm text-gray-700 placeholder-gray-400 border-2 border-[#ECEAE3] rounded-3xl focus:outline-none focus:border-blue-500"
        :placeholder="$t('search...').capitalize()"
    />
    <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none" v-if="!isLoading">
      <i class="fas fa-search text-blue-400"></i>
    </div>
    <div v-else class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
      <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
        <radialGradient id="a12" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)">
          <stop offset="0" stop-color="#9CA3AF"></stop>
          <stop offset=".3" stop-color="#9CA3AF" stop-opacity=".9"></stop>
          <stop offset=".6" stop-color="#9CA3AF" stop-opacity=".6"></stop>
          <stop offset=".8" stop-color="#9CA3AF" stop-opacity=".3"></stop>
          <stop offset="1" stop-color="#9CA3AF" stop-opacity="0"></stop>
        </radialGradient>
        <circle transform-origin="center" fill="none" stroke="url(#a12)" stroke-width="15" stroke-linecap="round"
                stroke-dasharray="200 1000" stroke-dashoffset="0" cx="100" cy="100" r="70">
          <animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="0.7" values="360;0"
                            keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform>
        </circle>
        <circle transform-origin="center" fill="none" opacity=".2" stroke="#9CA3AF" stroke-width="15"
                stroke-linecap="round" cx="100" cy="100" r="70"></circle>
      </svg>
    </div>
  </div>
</template>

<script>
import {libraryStore} from "@/views/library/stores/library.store";
import debounce from 'lodash/debounce';

export default {
  name: 'SearchComponent',

  setup() {
    const useLibraryStore = libraryStore();
    return {
      useLibraryStore,
    };
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      searchQuery: '',
      isLoading: false,
    };
  },

  created() {
    this.debouncedCheckQuery = debounce(this.checkQuery, 1000);
  },

  methods: {
    /**
     * Handles the input event for the search bar.
     * Sets the loading state to true and debounces the checkQuery method.
     */
    handleInput() {
      this.isLoading = true;
      this.debouncedCheckQuery();
    },

    /**
     * Checks the search query and updates the filters in the library store.
     * If the search query is not empty, it updates or adds the search filter.
     * If the search query is empty, it removes the search filter.
     * Applies the updated filters and sets the loading state to false.
     */
    async checkQuery() {
      const searchFilter = this.useLibraryStore.filters.find(filter => filter.name === 'search');

      if (this.searchQuery && this.searchQuery.trim()) {
        if (searchFilter) {
          searchFilter.filter[0].value = this.searchQuery;
          searchFilter.filter[0].checked = true;
        } else {
          this.useLibraryStore.filters.push({
            name: 'search',
            filter: [{
              value: this.searchQuery,
              checked: true,
            }],
          });
        }
      } else if (searchFilter) {
        this.useLibraryStore.filters = this.useLibraryStore.filters.filter(filter => filter.name !== 'search');
      }

      await this.useLibraryStore.setQueryFilters();
      this.isLoading = false;
    }
  },
};
</script>