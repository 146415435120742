<template>
  <button
      :class="[buttonClasses, hoverClass, { 'w-8 h-8': !alwaysShowText, 'w-auto': alwaysShowText }]"
      :disabled="isLoading || disabled"
      class="h-10 md:w-auto lg:text-md md:text-sm text-[8px] rounded-full flex items-center transition-all duration-200 ease-out hover:drop-shadow-lg"
      @click="emitName ? $emit(emitName) : null"
  >
    <span v-if="isLoading" class="flex items-center">
      <loading-component :size="size === 'small' ? '16' : '32'"/>
    </span>
    <span v-else class="flex items-center gap-2">
      <span v-if="prefixIcon" :class="[iconClasses]">
        <icon-component :icon="prefixIcon"></icon-component>
      </span>
      <span :class="alwaysShowText ? '' : 'hidden md:inline'">
        {{ buttonText ? $t(buttonText.toLowerCase()).capitalize() : '' }}
      </span>
    </span>
  </button>
</template>

<script>
import LoadingComponent from "@/components/LoadingComponent.vue";
import IconComponent from "@/components/forms/IconComponent.vue";

export default {
  name: "ButtonComponent",
  components: { IconComponent, LoadingComponent },
  props: {
    textAlign: {
      type: String,
      required: false,
      default: "center",
    },
    ghost: {
      type: Boolean,
      required: false,
      default: false,
    },
    emitName: {
      type: String,
      required: false,
    },
    buttonText: {
      type: String,
      default: '',
    },
    buttonColor: {
      type: String,
    },
    hoverColor: {
      type: String,
      required: false,
      default: null,
    },
    prefixIcon: {
      type: String,
      required: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabledColor: {
      type: String,
      required: false,
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
    alwaysShowText: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: 'default',
    },
  },
  computed: {
    iconClasses() {
      return [
        "text-lg",
        "mt-1.5 md:mt-0",
        this.selectColors(this.buttonColor).text,
        this.selectColors(this.buttonColor).hoverColor,
      ];

    },
    buttonClasses() {
      let baseClasses = [
        "transition-all",
        "duration-300",
        "ease-linear",
        "text-md",
        "rounded",
        "flex",
        "items-center",
        `justify-${this.textAlign}`,
        this.selectColors(this.buttonColor).hoverColor,
        this.selectColors(this.buttonColor).backgroundColor,
        this.selectColors(this.buttonColor).text,
        this.selectColors(this.buttonColor).border,
        this.selectColors(this.buttonColor).hoverBorderColor,
        this.disabled ? "cursor-not-allowed" : "cursor-pointer",
        this.disabled ? "opacity-60" : "opacity-100",
      ];

      if (this.size === 'small') {
        baseClasses.push('lg:h-6', 'lg:px-2 px-1', 'text-[10px]'); // Smaller height, padding, and font size
      } else {
        baseClasses.push('lg:h-10', 'lg:px-6 px-1', 'lg:text-[15px] text-xs'); // Default size
      }

      return baseClasses;
    },
    hoverClass() {
      return this.hoverColor ? `hover:bg-${this.hoverColor}` : "";
    },
  },
  methods: {
    selectColors(buttonColor) {
      if (buttonColor === "default") {
        if (this.disabled) {
          return {
            backgroundColor: "white",
            text: "gray-800",
            hoverBorderColor: "hover:border-gray-800",
            border: "border-2 border-default",
          };
        } else {
          return {
            backgroundColor: "white",
            text: "border-default",
            hoverBorderColor: "hover:border-gray-800",
            border: "border-2 border-default",
          };
        }
      }  else if (buttonColor === "cta") {
        if (this.disabled) {
          if (this.ghost) {
            return {
              backgroundColor: "bg-white",
              text: "cta-light",
              border: "border-2 border-cta-light",
              hoverBorderColor: "hover:border-cta-light",
            };
          } else {
            return {
              backgroundColor: "bg-ctalight",
              text: "text-white",
            };
          }
        } else {
          if (this.ghost) {
            return {
              backgroundColor: "white",
              text: "text-cta",
              border: "border-2 border-cta",
              hoverBorderColor: `hover:border-${this.hoverColor || "cta-light"}`,
              hoverColor: `hover:bg-${this.hoverColor || "white"}`,
            };
          } else {
            return {
              backgroundColor: "bg-cta",
              hoverColor: `hover:bg-${this.hoverColor || "ctalight"}`,
              text: "text-white",
            };
          }
        }
        }  else if (buttonColor === 'action') {
        return !this.ghost ? {
          backgroundColor: 'bg-action',
          hoverColor: 'hover:bg-action-light',
          text: 'text-white',
        } : {
          backgroundColor: 'bg-white',
          text: 'text-action',
          border: 'border-2 border-action',
          hoverBorderColor: 'hover:border-action-light',
        };
      } else {
        return {
          backgroundColor: 'bg-gray-400',
          text: 'text-white',
        };
      }
    }
  },
};
</script>
