import i18n from "@/i18n";
import AnalyticsView from "@/views/analytics/AnalyticsView.vue";

export default [
    {
        path: '/analytics',
        name: 'Analytics',
        component: AnalyticsView,
        meta: {
            title: i18n.global.t('analytics')
        }
    },
];
