<template>

  <div class="home">
    <user-dashboard-component
        v-if="this.useAuthStore?.user.roles && this.useAuthStore?.user?.roles[0]?.name === 'reseller_admin' || this.useAuthStore?.user.roles && this.useAuthStore?.user?.roles[0]?.name === 'school_director'"/>
    <demo-dashboard-component
        v-if="this.useAuthStore?.user.roles && this.useAuthStore?.user?.roles[0]?.name === 'demo_user'"/>
  </div>
</template>

<script>
import DemoDashboardComponent from "@/views/dashboard/components/DemoDasboardComponent.vue";
import UserDashboardComponent from '@/views/dashboard/components/UserDashboardComponent.vue'
import {authStore} from "@/views/auth/stores/auth.store";


export default {
  name: 'HomeView',
  components: {
    DemoDashboardComponent,
    UserDashboardComponent
  },

  setup() {
    const useAuthStore = authStore();

    return {
      useAuthStore
    }
  }

}
</script>