import {deleteData, getData, patchData, postData} from '@/helpers/api.helper';

const baseUrl = process.env.VUE_APP_API_URL;

export default {
    async getAllBooks(queries = '') {
        return getData(`${baseUrl}/api/books?${queries}`, null, {}, true);
    },
    async getAllCategories() {
        return getData(`${baseUrl}/api/books/categories`, null, {}, true);
    },
    async getBook(uuid) {
        return getData(`${baseUrl}/api/books/` + uuid, null, {}, true);
    },
    async postBook(data) {
        return postData(`${baseUrl}/api/books/create`, data, null, {}, true);
    },
    async uploadBook(data) {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };
        return postData(`${baseUrl}/api/books/pdf/upload`, data, null, config, true);
    },
    async uploadImage(data) {
        let config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };
        return postData(`${baseUrl}/api/books/image/upload`, data, null, config, true);
    },
    async updateBook(data) {
        return patchData(`${baseUrl}/api/books/update`, data, null, {}, true);
    },
    async getBookPDF(file_name) {
        return getData(`${baseUrl}/api/books/pdf/` + file_name, null, {}, true);
    },
    async getBookImage(image_name) {
        return getData(`${baseUrl}/api/books/image/` + image_name, null, {}, true);
    },
    async deleteBook(uuid) {
        return deleteData(`${baseUrl}/api/books/delete/` + uuid, null, null, {}, true);
    },
    async incrementBookViews(uuid) {
        return postData(`${baseUrl}/api/books/views/${uuid}`, {}, null, {}, true);
    },
    async incrementBookDownloads(uuid) {
        return postData(`${baseUrl}/api/books/downloads/${uuid}`, {}, null, {}, true);
    },
};
