<template>
  <div v-if="useUserStore.showEditUserProgramsModal"
       class="fixed inset-0 bg-gray-600 bg-opacity-50 index-50 overflow-y-auto h-full w-full" @click.self="closeModal">

    <div class="relative top-56  md:top-20 mx-auto p-5 border w-screen md:w-1/3 shadow-lg rounded-md bg-white">
      <div class="absolute top-0 right-0 pt-4 pr-4">
        <button @click="closeModal" class="text-gray-600 hover:text-gray-900">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <h3 class="text-lg leading-6 font-medium text-gray-900">{{ $t('choose your programs').capitalize() }}</h3>
      <hr>

      <form @submit.prevent="submitWorkspacePrograms()" class="pt-2">
        <checkbox-component v-for="program in programs"
                            class="pt-2"
                            :checked="useUserStore?.workspaceProgramsForm?.program_uuids?.includes(program.uuid)"
                            @update:checked="checkProgram(program.uuid, $event)"
                            :label="program.name"
                            :fieldId="program.uuid"
                            :key="program"
                            v-model="useUserStore.workspaceProgramsForm.program_uuids"/>
        <div class="flex justify-end">
          <button-component buttonText="Submit" buttonColor="action" prefix-icon="fi fi-rr-paper-plane-top" class="mt-4"
                            :is-loading="useUserStore.isLoadingUserBooks"/>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {userStore} from "@/views/users/stores/user.store.js";
import {libraryStore} from "@/views/library/stores/library.store.js";
import {agendaStore} from "@/views/agenda/stores/agenda.store.js";
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
import CheckboxComponent from "@/components/forms/CheckboxComponent.vue";

export default {
  name: 'EditUserProgramsViewComponent',
  components: {ButtonComponent, CheckboxComponent},
  setup() {
    const useUserStore = userStore();
    const useLibraryStore = libraryStore();
    const useAgendaStore = agendaStore();
    return {
      useUserStore,
      useLibraryStore,
      useAgendaStore
    };
  },
  props: {
    workspaceUuid: {
      type: String,
      required: true
    },
    workspacePrograms: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      programs: [],
    };
  },
  mounted() {
    this.setUserPrograms();
    this.getPrograms();
  },
  methods: {
    /**
     * Checks or unchecks a program and updates the selected programs in the store.
     * @param {string} programUuid - The UUID of the program.
     * @param {boolean} checked - The checked state of the program.
     */
    async checkProgram(programUuid, checked) {
      let storeSelectedPrograms = this.useUserStore.workspaceProgramsForm.program_uuids;
      if (checked) {
        storeSelectedPrograms.push(programUuid);
      } else {
        storeSelectedPrograms.splice(storeSelectedPrograms.indexOf(programUuid), 1);
      }
    },
    /**
     * Submits the selected workspace programs to the store.
     */
    async submitWorkspacePrograms() {
      this.useUserStore.isLoadingUserPrograms = true;
      await this.useUserStore.createWorkspacePrograms();
    },
    /**
     * Sets the initial selected programs for the user.
     */
    async setUserPrograms() {
      this.useUserStore.workspaceProgramsForm.program_uuids = this.useUserStore.selectedWorkspacePrograms.map(program => program.uuid);
    },
    /**
     * Fetches all programs from the agenda store.
     */
    async getPrograms() {
      await this.useAgendaStore.fetchAllPrograms();
      this.programs = this.useAgendaStore.programs;
    },
    /**
     * Closes the modal.
     */
    closeModal() {
      this.useUserStore.showEditUserProgramsModal = false;
    }
  }
};
</script>