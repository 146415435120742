<template>
  <div class="p-8 w-full overflow-x-auto lg:h-[calc(100vh-140px)] h-[calc(100vh-200px)] relative">
    <form @submit.prevent="updateWorkspace()" class="space-y-6">
      <div>
        <h3 class="py-3 lg:text-3xl text-xl font-bold text-black mb-4">
          {{ $t('workspace settings').capitalize() }}
        </h3>
        <div class="space-y-3">
          <input type="hidden" v-model="useAuthStore.workspace.uuid"/>
          <div class="my-3">
            <label class="block text-gray-700 text-sm font-semibold mb-1">Workspace Name</label>
            <div class="grid md:grid-cols-2 grid-cols-1 gap-3">
              <div class="rounded-3xl md:flex-grow">
                <input-component type="text" v-model="localWorkspaceName" :placeholder="useAuthStore.workspace.name"
                                 inputColor="primary" class="w-full font-semibold text-sm mb-0"/>
              </div>
              <button-component type="button" @click="handleWorkspaceLogoChange"
                                class="px-3 py-1 rounded shadow-sm" buttonText="change workspace logo"
                                :always-show-text="true" buttonColor="cta"/>
            </div>
            <input id="thumbnail" type="file" accept="image/*" @change="handleImageUpload" class="hidden"/>
          </div>
          <div class="my-3">
            <label class="block text-gray-700 text-sm font-semibold mb-1">Description</label>
            <div class="rounded-3xl">
              <input-component placeholder="add a description of your workspace here..."
                               v-model="useAuthStore.workspace.description"
                               inputColor="secondary" class="w-full font-semibold lg:p-2 text-sm mb-0"/>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h3 class=" lg:text-3xl text-xl font-bold text-black mb-4">
          {{ $t('profile settings').capitalize() }}
        </h3>
        <div class="grid md:grid-cols-2 grid-cols-1 gap-3">
          <div class="md:my-3">
            <label class="block text-gray-700 text-sm font-semibold mb-1">{{ $t('first name').capitalize() }}</label>
            <div class="rounded-3xl">
              <input-component type="text" v-model="useUserStore.form.first_name"
                               :placeholder="useUserStore.form.first_name"
                               buttonColor="action" class="w-full lg:p-2 text-sm font-semibold mb-0"/>
            </div>
          </div>
          <div class="md:my-3">
            <label class="block text-gray-700 text-sm font-semibold mb-1">{{ $t('last name').capitalize() }}</label>
            <div class="rounded-3xl">
              <input-component type="text" v-model="useUserStore.form.last_name"
                               :placeholder="useUserStore.form.last_name"
                               inputColor="primary" class="w-full lg:p-2 text-sm font-semibold mb-0"/>
            </div>
          </div>
          <div class="md:col-span-2 md:my-3">
            <label class="block text-gray-700 text-sm font-semibold mb-1">Email</label>
            <div class="flex items-center space-x-2">
              <div class="rounded-3xl flex-grow">
                <input-component type="email" v-model="useUserStore.form.email" :placeholder="useUserStore.form.email"
                                 inputColor="primary" class="w-full lg:p-2 text-sm font-semibold mb-0"
                                 :disabled="true"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="md:flex hidden bottom-0 left-8 space-x-3 mt-4">
        <button-component type="submit" class="md:px-3 py-1 rounded shadow-sm" buttonText="save settings"
                          :is-loading="useUserStore.isLoadingUserSettings" :always-show-text="true"
                          buttonColor="action"/>

        <button-component type="button" @click="confirmDeleteWorkspace"
                          class="px-3 py-1 bg-danger rounded shadow-sm" buttonText="delete workspace"
                          :always-show-text="true" button-color="action"
        />
      </div>
    </form>
  </div>
  <div v-if="!useUserStore.showEditUserModal" class="md:hidden mb-20 fixed bottom-4 left-4 right-4 z-40">
    <button-component :disabled="useUserStore.isLoadingUserSettings"
                      buttonText="Save Settings"
                      buttonColor="action"
                      :always-show-text="true"
                      :ghost="true"
                      class="mb-2 py-1 rounded-full w-full max-w-xs mx-auto text-md shadow-lg flex items-center justify-center"
                      @click="updateWorkspace"
    />
    <button-component :disabled="useUserStore.isLoadingUserSettings"
                      buttonText="Delete Workspace"
                      buttonColor="action"
                      :always-show-text="true"
                      :ghost="true"
                      class="mb-2 py-1 rounded-full w-full max-w-xs mx-auto text-md shadow-lg flex items-center justify-center"
                      @click="confirmDeleteWorkspace"
    />
  </div>
</template>

<script>
import {onMounted, ref} from 'vue';
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
import InputComponent from "@/components/forms/InputComponent.vue";
import {showDeleteConfirmation} from "@/helpers/sweetAlert";
import {authStore} from "@/views/auth/stores/auth.store";
import {userStore} from "@/views/users/stores/user.store";

export default {
  name: 'ShowSettings',
  components: {InputComponent, ButtonComponent},
  setup() {
    const useAuthStore = authStore();
    const useUserStore = userStore();
    const localWorkspaceName = ref('');

    onMounted(() => {
      localWorkspaceName.value = useAuthStore.workspace.name;
      useUserStore.form.first_name = useAuthStore.user.first_name;
      useUserStore.form.last_name = useAuthStore.user.last_name;
      useUserStore.form.email = useAuthStore.user.email;
    });

    /**
     * Updates the workspace with the new settings.
     */
    const updateWorkspace = async () => {
      try {
        useAuthStore.workspace.name = localWorkspaceName.value;
        useUserStore.isLoadingUserSettings = true;
        await useUserStore.updateWorkspace();
      } catch (error) {
        console.error("Error updating workspace:", error);
      } finally {
        useUserStore.isLoadingUserSettings = false;
      }
    };

    /**
     * Handles the image upload for the workspace logo.
     * @param {Event} event - The file input change event.
     */
    const handleImageUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        useUserStore.workspaceForm.image_name = file.name;
        useUserStore.workspaceForm.image = file;
      } else {
        useUserStore.workspaceForm.image_name = '';
        useUserStore.workspaceForm.image = null;
      }
    };

    /**
     * Triggers the file input click for changing the workspace logo.
     */
    const handleWorkspaceLogoChange = () => {
      document.getElementById('thumbnail').click();
    };

    /**
     * Confirms the deletion of the workspace.
     */
    const confirmDeleteWorkspace = () => {
      showDeleteConfirmation(() => deleteWorkspace(useAuthStore.workspace.uuid));
    };

    /**
     * Deletes the workspace.
     * @param {string} uuid - The UUID of the workspace to delete.
     */
    const deleteWorkspace = async (uuid) => {
      try {
        await useUserStore.deleteWorkspace(uuid);
      } catch (error) {
        console.error("Error deleting workspace:", error);
      }
    };

    return {
      useAuthStore,
      useUserStore,
      localWorkspaceName,
      updateWorkspace,
      handleImageUpload,
      handleWorkspaceLogoChange,
      confirmDeleteWorkspace,
    };
  }
};
</script>