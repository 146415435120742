<template>
  <div class="rounded-lg">
    <div class="grid xl:grid-cols-5 grid-cols-1 md:gap-8">
      <div class="flex flex-col col-span-2 xl:h-[calc(100vh-400px)] p-6">
        <div class="flex md:gap-4 mb-8">
          <span v-if="useLibraryStore.book?.name" class="md:text-3xl text-2xl font-medium">
            {{ useLibraryStore.book?.name }}
          </span>
          <div v-else class="inline-block">
            <SkeletonLoader :style="{ width: '200px', height: '32px', borderRadius: '0.5rem'}"/>
          </div>
        </div>
        <div class="gap-2 mb-4">
          <span v-for="category in book.categories" :key="category.id">
            <badge-component class="text-xs font-semibold rounded inline-block mr-2 mb-2 w-auto"
                             size="md"
                             :badge-color="category.background_color"
                             :text-color="category.text_color"
                             :badge-text="category.name

            "/>
          </span>
        </div>
        <div class="xl:mt-16">
          <label class="block font-medium text-xl mb-3">
            {{ $t('description').capitalize() }}:
          </label>

          <div class="bg-pink-500 mb-4" style="height: 2px; width: calc(100% - 1rem);"></div>

          <p v-if="!useLibraryStore.isLoadingBook" class="text-md text-gray-700">
            {{ useLibraryStore.book?.description.capitalize() }}
          </p>
          <div v-else>
            <SkeletonLoader :style="{ width: '100%', height: '150px', borderRadius: '0.5rem'}"/>
          </div>

          <div class="flex flex-col gap-3 mt-6">
            <button-component
                v-if="!!useLibraryStore.book?.is_demo && this.useAuthStore?.user.roles && this.useAuthStore?.user?.roles[0]?.name === 'demo_user'"
                :always-show-text="true"
                button-text="get customized lessons"
                buttonColor="action"
                class="w-full"
                @click="useAuthStore?.user?.request_upgrade ? useAppStore.showUpgradeFormModal = false : useAppStore.showUpgradeFormModal = true"/>
            <ButtonComponent class="w-full md:w-auto px-4" :always-show-text="true" buttonText="download lesson"
                             buttonColor="cta" text-align="start" hoverColor="cta-light"
                             @click="downloadBookWithWatermark" prefixIcon="fa-solid fa-download" ghost/>

<!--            <ButtonComponent class="w-full md:w-auto px-4" :always-show-text="true" buttonText="favorite"-->
<!--                             buttonColor="cta" text-align="start" hoverColor="cta-light"-->
<!--                             @click="markAsFavorite" prefixIcon="fa-solid fa-heart" ghost/>-->

<!--            <ButtonComponent class="w-full md:w-auto px-4" :always-show-text="true" buttonText="share lesson"-->
<!--                             buttonColor="cta" text-align="start" hoverColor="cta-light"-->
<!--                             @click="shareLesson" prefixIcon="fa-solid fa-share" ghost/>-->

            <ButtonComponent class="w-full md:w-auto px-4" :always-show-text="true" buttonText="show lesson"
                             buttonColor="cta" text-align="start" hoverColor="cta-light"
                             @click="() => { useLibraryStore.openBookModal = true; }" prefixIcon="fa-solid fa-eye"
                             ghost/>

          </div>
          <div v-if="!useLibraryStore.isLoadingBook" class="md:mt-6">
            <vue-plyr class="video-wrapper" v-if="useLibraryStore.book?.url">
              <div
                  data-plyr-provider="youtube"
                  :data-plyr-embed-id="useLibraryStore.book.url"
              ></div>
            </vue-plyr>
          </div>
          <div v-else class="pt-2">
            <SkeletonLoader :style="{ width: '100%', height: '150px', borderRadius: '0.5rem'}"/>
          </div>
        </div>
      </div>

      <div v-if="!useLibraryStore.isLoadingBook"
           class="xl:col-span-3 h-[calc(100vh-150px)] overflow-auto md:pl-10 md:pr-6">
        <vue-pdf-embed
            v-if="useLibraryStore.file"
            :source="useLibraryStore.file"
            :style="{ width: '100%', height: '100%' }"
            :pdf-options="{ scale: 0.7, pageLayout: 'twoColumnLeft' }"
            class="rounded-lg shadow-100xl border border-gray-200"
        />
      </div>

      <div v-else class="col-span-2 h-[calc(100vh-150px)] overflow-auto pl-10 pr-6">
        <SkeletonLoader :style="{ height: '100%', borderRadius: '0.5rem'}"/>
      </div>
    </div>

    <modal-show-book v-if="useLibraryStore.openBookModal"/>
  </div>
</template>

<script>
import {libraryStore} from "@/views/library/stores/library.store";
import ModalShowBook from "@/views/library/components/ModalShowBook.vue";
import VuePdfEmbed from "vue-pdf-embed";
import VuePlyr from "vue-plyr";
import Swal from "sweetalert2";
import "vue-plyr/dist/vue-plyr.css";
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
import {appStore} from "@/views/app.store";
import BadgeComponent from "@/components/forms/BadgeComponent.vue";
import {authStore} from "@/views/auth/stores/auth.store";

export default {
  name: "ShowBookComponent",
  components: {
    BadgeComponent,
    ButtonComponent,
    VuePdfEmbed,
    ModalShowBook,
    VuePlyr,
  },
  setup() {
    const useLibraryStore = libraryStore();
    const useAppStore = appStore();
    const useAuthStore = authStore()

    /**
     * Downloads the book with a watermark and increments the download count.
     */
    const downloadBookWithWatermark = async () => {
      await useLibraryStore.setBookWithWatermark(useLibraryStore.book, true);
      await useLibraryStore.incrementDownloads(useLibraryStore.book.uuid);
    };

    /**
     * Marks the book as favorite.
     */
    const markAsFavorite = () => {
    };

    /**
     * Shares the lesson URL by copying it to the clipboard.
     */
    const shareLesson = () => {
      navigator.clipboard
          .writeText(window.location.href)
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "Copied!",
              text: "Lesson URL copied to clipboard!",
              timer: 1500,
              showConfirmButton: false,
            });
          })
          .catch((err) => {
            console.error("Failed to copy URL: ", err);
          });
    };

    return {
      useLibraryStore,
      useAppStore,
      useAuthStore,
      downloadBookWithWatermark,
      markAsFavorite,
      shareLesson,
    };
  },
  mounted() {
    this.handleIncrementViews();
  },
  methods: {
    /**
     * Increments the view count of the book.
     */
    async handleIncrementViews() {
      await this.useLibraryStore.incrementViews(this.useLibraryStore?.book?.uuid);
    },
  },
  props: {
    book: {
      required: true,
      type: Object,
    },
  },
};
</script>