<template>
  <div>
    <div v-if="!useCategoryStore.categoriesListLoading">
      <template v-for="category in categories" :key="category.id">
        <div class="cursor-pointer my-2 px-4 rounded-xl justify-between items-center"
             :class="[depth === 0 ? 'hover:bg-gray-50 py-4 bg-gray-100 flex' : 'py-0']">
          <div
              :class="[`py-3 pl-${depth * 4} text-sm font-medium text-gray-900`, depth === 0 ? 'cursor-pointer hover:underline' : 'hover:cursor-default grid grid-cols-2']"
              @click="toggle(category.id)">
            <div class="col-span-1">{{ category.name }}</div>

            <span class="col-span-1" v-if="depth !== 0">
                <circle-component :background_color="category.background_color || '#ECEAE3'" size="sm" />
            </span>
          </div>
          <div>
            <button v-if="depth === 0" @click.stop="openEditModal(category.id)"
                    class="text-white mr-2 pt-1 w-8 h-8 bg-cta hover:bg-ctalight rounded-full">
              <i class="fi fi-rr-pencil"></i>
            </button>
            <button v-if="depth === 0" @click.stop="confirmDelete(category.id)" class="text-white pt-1 w-8 h-8 rounded-full bg-red-500 hover:bg-red-700 ">
              <i class="fi fi-rr-trash"></i>
            </button>
          </div>
        </div>
        <div v-if="openCategories[category.id]" class="pl-4">
          <category-list v-if="category.subcategories && category.subcategories.length"
                         :categories="category.subcategories"
                         :depth="depth + 1"/>
        </div>
      </template>
    </div>
    <div v-else>

      <div v-for="i in 5" :key="i">
        <div class="pl-4 py-4">
          <SkeletonLoader :style="{width: '100%', display: 'block', height: '30px', borderRadius: '0.5rem'}"/>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {categoryStore} from "@/views/categories/stores/category.store";
import {showDeleteConfirmation} from "@/helpers/sweetAlert";
import CircleComponent from "@/components/forms/CircleComponent.vue";

export default {
  name: "CategoryList",
  components: {CircleComponent},
  props: {
    /**
     * The list of categories to display.
     * @type {Array}
     */
    categories: Array,
    /**
     * The depth level of the category in the hierarchy.
     * @type {Number}
     */
    depth: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    let useCategoryStore = categoryStore();
    return {
      useCategoryStore,
    };
  },
  data() {
    return {
      openCategories: {},
    };
  },
  methods: {
    /**
     * Toggles the visibility of subcategories for a given category.
     * @param {Number} categoryId - The ID of the category to toggle.
     */
    toggle(categoryId) {
      this.openCategories[categoryId] = !this.openCategories[categoryId];
    },
    /**
     * Confirms the deletion of a category.
     * @param {Number} categoryId - The ID of the category to delete.
     */
    confirmDelete(categoryId) {
      showDeleteConfirmation(() => this.deleteCategory(categoryId));
    },
    /**
     * Deletes a category.
     * @param {Number} categoryId - The ID of the category to delete.
     * @returns {Promise<void>}
     */
    async deleteCategory(categoryId) {
      try {
        this.useCategoryStore.selectedCategoryId = categoryId;
        await this.useCategoryStore.deleteCategory();
      } catch (error) {
        console.error("Error deleting category:", error);
      }
    },
    /**
     * Opens the edit modal for a category.
     * @param {Number} mainCategoryId - The ID of the category to edit.
     */
    openEditModal(mainCategoryId) {
      this.useCategoryStore.selectedCategoryId = mainCategoryId;
      this.useCategoryStore.openEditModal();
    },
  },
};
</script>