import {defineStore} from "pinia";
import agendaService from "@/views/agenda/services/agenda.service";
import router from "@/router";
import {showSuccessAlert} from "@/helpers/sweetAlert";

export const agendaStore = defineStore('agendaStore', {
    state: () => {
        return {
            programs: [],
            program: {},
            form: {
                uuid: '',
                name: '',
                description: '',
                book_uuids: []
            },
            books: [],
            showEditProgramModal: false,
            editMode: false,
            loadEditProgram: false,
            programsLoading: true,
            queryFilter: '',
            programBooks: []
        }
    },
    actions: {
        /**
         * Fetches all programs.
         * @returns {Promise<void>}
         */
        async fetchAllPrograms() {
            this.programsLoading = true;
            try {
                const response = await agendaService.getAllPrograms(this.queryFilter);
                this.programs = response.data.data;
                this.programsLoading = false;
            } catch (error) {
                console.error('Error fetching programs:', error);
            }
        },

        /**
         * Deletes a program.
         * @returns {Promise<void>}
         */
        async deleteProgram() {
            this.programsLoading = true;
            try {
                let form = {
                    uuid: this.program.uuid
                }

                await agendaService.deleteProgram(form).then(async () => {
                    router.push({path: '/agenda'})
                });

            } catch (error) {
                console.error('Error deleting program:', error);
            }
        },

        /**
         * Fetches a specific program by UUID.
         * @param {string} uuid - The UUID of the program to fetch.
         * @returns {Promise<void>}
         */
        async fetchProgram(uuid) {
            try {
                await agendaService.getClassPrograms(uuid).then(async response => {
                    this.program = response.data.data;
                    this.setProgramBooks();
                });
            } catch (error) {
                console.error('Error fetching program:', error);
            }
        },

        /**
         * Creates a new program.
         * @returns {Promise<void>}
         */
        async createProgram() {
            try {
                await agendaService.postProgram(this.form).then(async () => {
                    if (!this.editMode) {
                        await this.fetchAllPrograms()
                    } else {
                        await this.fetchProgram(this.program.uuid)
                    }
                    this.closeModal()
                    showSuccessAlert('success', 'program successfully added');
                    this.editMode = false;
                    this.loadEditProgram = false;
                })
            } catch (error) {
                console.error('Error creating program:', error);
            }
        },

        /**
         * Updates an existing program.
         * @returns {Promise<void>}
         */
        async updateProgram() {
            try {
                await agendaService.updateProgram(this.form).then(async () => {
                    await this.fetchProgram(this.form.uuid)
                    this.closeModal()
                    showSuccessAlert('success', 'program successfully updated');
                    this.editMode = false;
                    this.loadEditProgram = false;
                })
            } catch (error) {
                console.error('Error updating program:', error);
            }
        },

        /**
         * Closes the edit program modal and resets the form.
         */
        closeModal() {
            this.showEditProgramModal = false;
            this.books = [];
            this.resetForm();
        },

        /**
         * Resets the form to its initial state.
         */
        resetForm() {
            this.form = {
                uuid: '',
                name: '',
                description: '',
                book_uuids: []
            }
        },

        /**
         * Sets the program books in the form.
         * @returns {Promise<void>}
         */
        async setProgramBooks() {
            this.form.uuid = this.program.uuid;
            this.form.description = this.program.description;
            this.form.name = this.program.name;
            this.form.book_uuids = this.program.books?.map(book => book.uuid);
        }
    }
});