<template>
  <div v-if="message" class="fixed inset-x-0 top-0 p-4 text-center z-50 transition-all duration-300 ease-in-out">
    <div :class="{
           'bg-red-100 text-red-700 border border-red-200 shadow-lg rounded-lg': type === 'error',
           'bg-green-100 text-green-700 border border-green-200 shadow-lg rounded-lg': type === 'success',
           'bg-blue-100 text-blue-700 border border-blue-200 shadow-lg rounded-lg': type === 'info',
         }" class="p-2 mx-auto max-w-lg relative">
      <p class="font-semibold text-sm md:text-base"><span v-html="message"></span></p>
      <div class="absolute left-0 w-full h-1 bg-gray-200 rounded-xl">
        <div class="h-full rounded-lg" :class="{
           'bg-red-300': type === 'error',
           'bg-green-300': type === 'success',
           'bg-blue-300': type === 'info',
         }" :style="{ width: percentage }"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupMessage',

  props: {
    message: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: 'error',
    },
    percentage: {
      type: String,
      required: false,
      default: '0%',
    },
  },
}
</script>