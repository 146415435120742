<template>
  <div class="fixed inset-0 z-50 flex items-center justify-center overflow-hidden bg-gray-600 bg-opacity-50">
    <div class="relative flex h-screen w-full max-w-3xl flex-col items-center px-4 md:px-0">
      <div class="absolute right-4 top-8 z-10 md:right-8 md:top-4">
        <button
          @click="useAppStore.showUpgradeFormModal = false"
          class="flex h-8 w-8 items-center justify-center rounded-full border-2 border-white bg-white text-pink-500 shadow-md hover:bg-pink-100"
        >
          &times;
        </button>
      </div>
      <div 
        class="calendly-inline-widget h-[calc(100vh-100px)] w-full max-w-2xl overflow-y-auto p-4 md:w-[90%]"
        data-url="https://calendly.com/fluitend_leren/methode-presentatie-plannen"
      >
      </div>
    </div>
  </div>
</template>

<script>
import {appStore} from "@/views/app.store";
import {authStore} from "@/views/auth/stores/auth.store";
import {userStore} from "@/views/users/stores/user.store";

export default {
  name: "UpgradeFormModal",
  setup() {
    const useAuthStore = authStore();
    const useUserStore = userStore();
    const useAppStore = appStore();

    return {
      useAppStore,
      useAuthStore,
      useUserStore
    };
  },
  mounted() {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);
  }
};
</script>