<template>
  <div v-if="useUserStore.showEditUserModal"
       class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto z-50 justify-center items-center"
       @click.self="closeModal">
    <div class="relative top-20 mx-auto p-5 border w-full md:w-1/3 shadow-lg rounded-md bg-white">
      <div class="absolute -top-4 -right-4 transform">
        <button @click="closeModal"
                class="flex items-center justify-center w-8 h-8 bg-white rounded-full shadow-md text-pink-500 hover:bg-pink-100 border-2 border-white">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <h3 class="text-xl leading-6 font-medium text-gray-900" v-if="!useUserStore.editMode">
        {{ $t('create user').capitalize() }}
      </h3>
      <h3 class="text-lg leading-6 font-medium text-gray-900" v-else>
        {{ $t('update user').capitalize() }}
      </h3>
      <hr class="px-2 mb-4">
      <form @submit.prevent="submitUser()">
        <div class="pt-2 grid grid-cols-2 gap-4">
          <input-component
              input-type="hidden"
              placeholder="Id"
              v-model="useUserStore.form.uuid"
              inputColor="primary"
              class="w-full col-span-2"
              label="id"
              required
              v-if="useUserStore.form.uuid"
          />
          <input-component
              input-type="text"
              placeholder="Wouter"
              v-model="useUserStore.form.first_name"
              inputColor="primary"
              class="w-full"
              label="First Name"
              required
          />
          <input-component
              input-type="text"
              placeholder="van der Laan"
              v-model="useUserStore.form.last_name"
              inputColor="primary"
              class="w-full"
              label="Last Name"
              required
          />
          <input-component
              input-type="text"
              placeholder="jon.doe@gmail.com"
              v-model="useUserStore.form.email"
              inputColor="secondary"
              class="w-full mb-4 col-span-2"
              label="email"
              required
          />
          <select-component
              v-if="useUserStore.roles.length > 1"
              v-model="useUserStore.form.role"
              inputColor="primary"
              label="Role"
              required
              class="w-full mb-4 col-span-2"
              :options="useUserStore.roles"
          />
        </div>
        <div class="mb-4">
          <button-component buttonText="Submit" buttonColor="action" class="w-full"
                            prefix-icon="fi fi-rr-paper-plane-top" :is-loading="useUserStore.isLoading"
                            :disabled="useUserStore.disabled"/>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
import InputComponent from "@/components/forms/InputComponent.vue";
import {userStore} from "@/views/users/stores/user.store.js";
import SelectComponent from "@/components/forms/SelectComponent.vue";

export default {
  name: 'CreateUserComponent',
  components: {
    SelectComponent,
    ButtonComponent,
    InputComponent
  },

  setup() {
    const useUserStore = userStore();

    return {
      useUserStore
    };
  },

  mounted() {
    this.useUserStore.getRoles().then(() => {
      this.setDefaultRole(this.useUserStore.roles);
    });

    this.$watch('useUserStore.roles', (roles) => {
      this.setDefaultRole(roles);
    });
  },

  methods: {
    setDefaultRole(roles) {
      if (roles.length === 1) {
        this.useUserStore.form.role = roles[0].value;
      } else {
        const adminRole = roles.find(role => role.value === 'reseller_admin');
        if (adminRole) {
          this.useUserStore.form.role = adminRole.value;
        }
      }
    },
    /**
     * Submits the user form. If in edit mode, updates the user, otherwise creates a new user.
     */
    async submitUser() {
      if (this.useUserStore.editMode) {
        await this.useUserStore.updateBook();
      } else {
        await this.useUserStore.createUser();
      }
      this.closeModal();
    },
    /**
     * Closes the modal.
     */
    closeModal() {
      this.useUserStore.showEditUserModal = false;
    }
  }
};
</script>