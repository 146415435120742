<template>
  <div>
    <div @click="toggleCollapse">
      <div v-if="filter.filterType" class="mb-1 bg-default md:bg-white px-4 md:px-0">
        <label class="flex items-center space-x-2">
          <div
              v-if="filter.filterType === 'checkbox'"
              @click.stop="setCheck(filter)"
              :class="{
              'bg-pink-500': filter.checked,
              'bg-white': !filter.checked,
            }"
              class="h-5 w-5 rounded-full border-1.5 border-black hover:cursor-pointer flex items-center justify-center"
          >
          </div>
          <span
              v-if="filter.filterType !== 'child'"
              class="text-sm font-semibold hover:cursor-pointer"
          >
            {{ filter.name }}
          </span>
          <span
              v-if="hasChildren"
              class="transform duration-200 inline-flex items-center hover:cursor-pointer"
              :class="{ '-rotate-90': isCollapsed }"
          >
            <i class="fi fi-rr-angle-small-down text-sm pt-2"></i>
          </span>
        </label>
      </div>
    </div>

    <div v-if="filter.filterType === 'child'" class=" mb-1">
      <label class="flex items-center space-x-2">
        <div
            @click.stop="setCheck(filter)"
            :class="{
            'bg-pink-500': filter.checked,
            'bg-white': !filter.checked,
          }"
            class="h-3 w-3 rounded-full border-2 border-black hover:cursor-pointer flex items-center justify-center"
        >
        </div>
        <span class="text-sm">{{ filter.name }}</span>
      </label>
    </div>

    <div
        v-if="hasChildren"
        :class="isCollapsed ? 'max-h-0 opacity-0' : 'max-h-xl opacity-100'"
        class="overflow-hidden transition-all duration-500 ease-in-out border-gray-200 pl-4 mt-2"
    >
      <filter-item
          v-for="(nestedFilter, index) in filter.filter"
          :key="index"
          :filter="nestedFilter"
          :disabled="disabled"
      >
      </filter-item>
    </div>
  </div>
</template>

<script>
import {libraryStore} from "@/views/library/stores/library.store";

export default {
  name: "FilterItem",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const useLibraryStore = libraryStore();
    return {
      useLibraryStore,
    };
  },
  data() {
    return {
      isCollapsed: false,
    };
  },
  methods: {
    /**
     * Toggles the checked state of the filter and fetches the updated list of books.
     * @param {Object} filter - The filter object.
     */
    async setCheck(filter) {
      filter.checked = !filter.checked;
      this.useLibraryStore.isLoadingBooks = true;
      await this.useLibraryStore.setQueryFilters("categories");
      await this.useLibraryStore.fetchAllBooks();
    },
    /**
     * Toggles the collapsed state of the filter item.
     */
    toggleCollapse() {
      if (this.hasChildren) {
        this.isCollapsed = !this.isCollapsed;
      }
    },
  },
  computed: {
    /**
     * Checks if the filter has nested children.
     * @returns {Boolean} True if the filter has children, false otherwise.
     */
    hasChildren() {
      return this.filter.filter && this.filter.filter.length;
    },
  },
};
</script>