import {deleteData, getData, patchData, postData} from '@/helpers/api.helper';

const baseUrl = process.env.VUE_APP_API_URL;

export default {
    async getAllCategories() {
        return getData(`${baseUrl}/api/books/categories`, {}, {}, true);
    },
    async fetchCategory(categoryId) {
        return getData(`${baseUrl}/api/books/categories/${categoryId}`, {}, {}, true);
    },
    async updateCategory(data, categoryId) {
        return patchData(`${baseUrl}/api/books/categories/${categoryId}`, data, {}, {}, true);
    },
    async postCategory(data) {
        return postData(`${baseUrl}/api/books/categories/create`, data, {}, {}, true)
    },
    async deleteCategory(data) {
        return deleteData(`${baseUrl}/api/books/categories/delete`, data, {}, {}, true)
    },

}