<template>
  <div v-if="modalIsOpen" class="fixed inset-0 flex justify-end items-start z-50 bg-black bg-opacity-50" @click="closeModal">
    <div
        class="bg-white w-full max-w-[500px] h-[85vh] mt-24 mr-8 rounded-[30px] shadow-lg p-8 relative z-70 overflow-y-auto transform -translate-x-10"
        @click.stop
    >
      <h1 class="text-2xl font-bold text-[#48B0ED] mb-6 flex items-center">
        <i class="fi fi-rr-bell mt-2 mr-3"></i>{{ $t('notifications').capitalize() }}
      </h1>

      <div class="p-6">
        <div class="flex items-start gap-4 mb-8">
          <div class="bg-[#48B0ED] text-white rounded-full w-24 h-11 flex items-center justify-center text-lg font-bold">
            <i class="fas fa-exclamation"></i>
          </div>
          <p class="text-gray-900 text-base">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.
          </p>
        </div>
        <hr class="border-gray-200 my-6">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modalIsOpen: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>