import { authStore } from "@/views/auth/stores/auth.store";

function checkCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift() !== '';
    return false;
}

export function getLogin() {
    return checkCookie('accessToken');
}

export function getWorkspace() {
    return checkCookie('workspace');
}

export function getEmailVerification() {
    return getCookieValue('user');
}

function getCookieValue(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
    return null;
}

export function logout() {
    return authStore().logout();
}

export function getUserProfile() {
    authStore().getProfile();
}

export async function checkPermission(permission) {
    return await authStore().checkPermission(permission);
}

export { getCookieValue };

export function canUseCookies() {
    return getCookieValue('cookies_accepted') === 'true';
}

export function hasDeniedCookies() {
    return getCookieValue('cookies_accepted') === 'false';
}

export function hasEssentialCookies() {
    return getCookieValue('cookies_accepted') === 'essential';
}