<template>
  <div class="bg-white flex flex-col sm:flex-row min-h-screen">
    <div class="flex flex-col justify-center items-center w-full sm:w-1/2 p-4 sm:p-8 max-h-screen overflow-y-auto">
      <img src="../../assets/logo_transparant.png" alt="Fluitend Leren Logo" class="mb-8">

      <form @submit.prevent="createWorkspace()" class="w-2/3 flex flex-col items-center mx-auto">
        <h2 class="text-2xl mb-8 text-center">{{ $t('create profile').capitalize() }}</h2>
        <h2 class="pt-6">{{ $t('profile details').capitalize() }}</h2>
        <hr class="mb-3">
        <input-component type="text" v-model="useUserStore.workspaceForm.name" placeholder="name of your institution"
                         inputColor="primary" label="institute name" class="w-full mb-4" required/>
        <button-component type="submit" class="md:text-xl w-full mt-3" buttonText="create" buttonColor="action"
                          always-show-text="true"/>
      </form>
    </div>

    <div class="hidden sm:block absolute inset-0 w-full h-full pointer-events-none">
      <img src="../../assets/thumbnail_fluitendleren.png" alt="Girl Holding Book" class="w-full h-full object-cover">
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
import InputComponent from "@/components/forms/InputComponent.vue";
import {authStore} from "@/views/auth/stores/auth.store";
import {userStore} from "@/views/users/stores/user.store";
import {useRouter} from 'vue-router';

export default {
  name: 'CreateWorkspaceView',
  components: {InputComponent, ButtonComponent},

  setup() {
    const useAuthStore = authStore();
    const useUserStore = userStore();
    const router = useRouter();
    const notification = ref({
      message: '',
      type: 'success',
    });

    return {
      useAuthStore,
      useUserStore,
      router,
      notification,
    };
  },

  methods: {
    /**
     * Creates a new workspace.
     * @returns {Promise<void>}
     */
    async createWorkspace() {
      await this.useUserStore.createWorkspace();
    },

    /**
     * Handles the image upload event.
     * @param {Event} event - The image upload event.
     */
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.useUserStore.workspaceForm.image_name = file.name;
        this.useUserStore.workspaceForm.image = file;
      } else {
        this.useUserStore.workspaceForm.image_name = '';
        this.useUserStore.workspaceForm.image = null;
      }
    },

    /**
     * Handles the login process.
     * @returns {Promise<void>}
     */
    async handleLogin() {
      let loginSuccess = false;
      try {
        await this.useAuthStore.login();
        loginSuccess = true;
      } catch (error) {
        this.notification.message = 'Login failed. Please try again.';
        this.notification.type = 'error';
        setTimeout(() => {
          this.notification.message = '';
          this.notification.type = 'success';
        }, 3000);
      }

      if (loginSuccess) {
        this.notification.message = 'Login Success!';
        this.notification.type = 'success';
        setTimeout(() => {
          this.notification.message = '';
          this.notification.type = 'success';
        }, 3000);
        this.router.push({name: 'Dashboard'});
      }
    },

    /**
     * Handles the credential response.
     * @param {Object} response - The credential response object.
     */
    handleCredentialResponse(response) {
      console.log(response.credential);
    },
  },
};
</script>